import Language from "../../helpers/language";

export const ContentTextWidget = props => {
    const { access, content, onChange } = props;
    const text = content.text || ""

    const handleChange = e => {
        if ( !access.write ) {
            return
        }

        onChange('content', { ...content, [e.target.id]: e.target.value });
    };

    return (
        <div className='basic-modal__field--add-question width-100'>
            <label className='basic-modal__label' htmlFor='text'>
                {Language.getTitleCase('Question')}
            </label>
            <textarea
                className='basic-modal__input basic-modal__input--add-question width-100'
                placeholder={Language.getSentenceCase('Type question text here') + '...'}
                id='text'
                readOnly={!access.write}
                onChange={handleChange}
                value={text}></textarea>
        </div>
    );
};
