import React from "react";
import Language from "../../helpers/language";
import { ContentType } from "../../helpers/consts";
import { InputWithArrows } from "../../components/input_with_arrows";

export const CurriculumSlotModal = (props) => {
    const { slot, category_summaries, tag_summaries, onChange, onClose } = props

    let c_uids = {}
    slot.category_uids.forEach( x => { c_uids[x] = true })

    let t_uids = {}
    slot.tag_uids.forEach( x => { t_uids[x] = true })

    let ct_uids = {}
    slot.content_types.forEach( x => { ct_uids[x] = true })

    let content_types = Object.entries(ContentType).map(([key, name]) => ({
        uid: name,
        key,
        name,
        count: 0,
    }))

    category_summaries.forEach(x => {
        if ( Object.keys(c_uids).length > 0 && !(x.uid in c_uids) ) {
            return
        }

        //Add in these types
        Object.entries(x.question_count_by_type).forEach(([k,v]) => {
            content_types.forEach(ct => {
                if ( ct.name.toLowerCase() == k ) {
                    ct.count += v
                }
            })
        })
    })

    const handleRepeat = (value) => {
        slot.count = value
        onChange( slot )
    }

    const handleChecked = (action, key, value) => {
        let obj = {}
        slot[key].forEach( x => obj[x] = true )

        //Run the opperation
        if ( action ) {
            obj[value] = true
        }
        else {
            delete obj[value]
        }

        slot[key] = Object.keys( obj )
        onChange( slot )
    }

    return (
        <div className='basic-modal basic-modal--add-group' style={{height: '100%', marginTop: '8px'}}>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('edit slot')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="basic-modal__slot_container">
                <div className="basic-modal__slot_component">
                    <label className='basic-modal__label'>
                        {Language.getTitleCase("question count")}
                    </label>
                    <div style={{marginTop: '8px', marginLeft: '8px'}}>
                        <InputWithArrows
                            min={1}
                            value={slot.count}
                            onChange={e => handleRepeat(e.target.value)}
                            />
                    </div>
                </div>

                {category_summaries.length > 0 &&
                    <div className="basic-modal__slot_component">
                        <label className='basic-modal__label'>
                            {Language.getTitleCase("categories")}
                        </label>
                        <div className='basic-modal__slot_checkbox_container'>
                            {Object.entries(category_summaries).map(([key, cat]) =>
                                <div style={{ marginTop: '4px' }}
                                     className='customization-checkbox grid-item'
                                     key={`category_summaries_${key}`}>
                                    <input
                                        type='checkbox'
                                        aria-label="checkbox"
                                        name={key}
                                        readOnly
                                        checked={cat.uid in c_uids}
                                        onClick={() => handleChecked( !(cat.uid in c_uids), 'category_uids', cat.uid)}
                                    />
                                    <span className='checkmark'></span>
                                    <label htmlFor='users-can-edit'
                                           onClick={() => handleChecked( !(cat.uid in c_uids), 'category_uids', cat.uid)}>
                                        {cat.name} ( {cat.question_count} )
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                }

                {tag_summaries.length > 0 &&
                    <div className="basic-modal__slot_component">
                        <label className='basic-modal__label'>
                            {Language.getTitleCase("tags")}
                        </label>
                        <div className='basic-modal__slot_checkbox_container'>
                            {Object.entries(tag_summaries).map(([key, tag]) =>
                                <div style={{ marginTop: '4px' }}
                                     className='customization-checkbox grid-item'
                                     key={`tag_summaries_${key}`}>
                                    <input
                                        type='checkbox'
                                        aria-label="checkbox"
                                        name={key}
                                        readOnly
                                        checked={tag.uid in t_uids}
                                        onClick={() => handleChecked( !(tag.uid in t_uids), 'tag_uid', tag.uid)}
                                    />
                                    <span className='checkmark'></span>
                                    <label htmlFor='users-can-edit'
                                           onClick={() => handleChecked( !(tag.uid in t_uids), 'tag_uid', tag.uid)}>
                                        {tag.name} ( {tag.question_count} )
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                }

                <div className="basic-modal__slot_component">
                    <label className='basic-modal__label'>
                        {Language.getTitleCase("content types")}
                    </label>
                    <div className='basic-modal__slot_checkbox_container'>
                        {content_types.map(ct =>
                            <div style={{ marginTop: '4px' }}
                                 className='customization-checkbox grid-item'
                                 key={`content_type_${ct.key}`}>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name={ct.key}
                                    readOnly
                                    checked={ct.uid in ct_uids}
                                    onClick={() => handleChecked( !(ct.uid in ct_uids), 'content_types', ct.name)}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='users-can-edit'
                                       onClick={() => handleChecked( !(ct.uid in ct_uids), 'content_types', ct.name)}>
                                    {ct.name} ( {ct.count} )
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
