import React, {useState} from "react";
import Language from "../../helpers/language";
import {AnswerType, ContentType} from "../../helpers/consts";
import {SelectItems} from "../select_items";
import {Drip7Dropdown} from "../drip7_dropdown";

export const CurriculumSpecificSlotWidget = props => {
    const { category_summaries, category_questions, question_uids, category_uids, slots, onChange } = props;

    const [state, setState] = useState({
        category_uid: '',
        tag_uid: '',
        content_type: '',
        answer_type: '',
    })
    const { category_uid, tag_uid, content_type, answer_type } = state

    const handleSlotChange = ( uids ) => {
        const value = uids.map( x => ({ question_uid: x }))
        onChange( { target: { id: 'slots', value }})
    }

    const handleChange = (e) => {
        setState(prev => ({...prev,
            [e.target.id]: e.target.value,
        }))
    }

    const ctt = (content_type == null || content_type == undefined || content_type == "")? {name: "Content", value: ""}: {name: Language.getTitleCase(content_type), value: content_type}
    const att = (answer_type == null || answer_type == undefined || answer_type == "")? {name: 'Answer', value: ''}: {name: Language.getTitleCase(answer_type), value: answer_type}

    const cat_title = category_summaries.find( x => x.uid == category_uid ) || { name: "Categories", uid: ''}
    const category_entries = [{name: '---', uid: ''}].concat(Object.values(category_summaries)
        .sort((a, b) => a.name.localeCompare(b.name)))

    const cat_lookup = {}
    category_uids.forEach( x => { cat_lookup[x] = true })
    const question_lookup = {}
    question_uids.forEach( x => { question_lookup[x] = true })

    let questions_raw = {}
    category_questions.forEach(x => {
        if ( category_uid != '' && category_uid != x.uid ) {
            return
        }

        const include_all = x.uid in cat_lookup
        x.questions.forEach(q => {
            if ( content_type != '' && content_type != q.content_type ) {
                return
            }
            if ( answer_type != '' && answer_type != q.answer_type ) {
                return
            }

            if ( include_all || q.uid in question_lookup ) {
                const c = (Array.isArray(q.follow_up_uids) && q.follow_up_uids.length > 0 || Object.keys(q.follow_up_uids).length > 0)? '[C] ': ''
                questions_raw[q.uid] = { ...q, name: `${c}${q.name}`}
            }
        })
    })

    return (
        <SelectItems
            title={Language.getTitleCase( "questions" )}
            selected={slots.map(x => x.question_uid)}
            items={Object.values(questions_raw)}
            onChange={handleSlotChange}>

                <div className="buttons">
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='category_uid'
                            name='category_uid'
                            value={cat_title}
                            entries={category_entries}
                            display={x => x.name}
                            overflow={'max-h-60'}
                            onChange={x => handleChange({ target: { id: 'category_uid', value: x.target.value.uid }})}
                            />
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='content_type'
                            name='content_type'
                            value={ctt}
                            entries={[{name: '---', value: ''}].concat(Object.values(ContentType).map(ct => ({name: ct, value: Language.getTitleCase(ct)})))}
                            display={x => x.name}
                            onChange={x => handleChange({ target: { id: 'content_type', value: x.target.value.value }})}
                            overflow={'max-h-60'}
                        />
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='answer_type'
                            name='answer_type'
                            align='right'
                            value={att}
                            entries={[{name: '---', value: ''}].concat(Object.values(AnswerType).map(at => ({name: at, value: Language.getTitleCase(at)})))}
                            display={x => x.name}
                            onChange={x => handleChange({ target: { id: 'answer_type', value: x.target.value.value }})}
                            overflow={'max-h-60'}
                        />
                    </div>
                </div>
        </SelectItems>
    );
};
