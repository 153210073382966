import React, { useEffect, useState } from "react";
import Language from "../../helpers/language";

export const AnswerTypeTextArea = React.forwardRef((props, ref) => {
    const { answer, response, onChanged } = props;
    const [state, setState] = useState({
        user_answer: ""
    });
    const { user_answer } = state;

    let answer_display = ""
    if ( 'correct' in answer ) {
        answer_display = typeof answer.correct == "object" ? answer.correct[0] : answer.correct;
    }

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            setState({ ...state, user_answer: "" });
        },
        attemptAnswer: () => {
            if (user_answer == "") {
                return false;
            }

            handleSubmitAnswer();
            return true;
        }
    }));

    const handleSubmitAnswer = () => {
        const ualc = user_answer.toLowerCase();

        //Is this answer now correct?
        let is_correct = false;
        if ( !('correct' in answer) ) {
            is_correct = true
        }
        else if (typeof answer.correct == "object") {
            for (let i = 0; i < answer.correct.length; i++) {
                const ans = answer.correct[i]
                    .toLowerCase()
                    .replace(/^\s+/, "")
                    .replace(/\s+$/, "");
                is_correct = is_correct || ualc.search(ans) >= 0;
            }
        }
        else {
            const correct = answer.correct || ""
            const ans = correct
                .toLowerCase()
                .replace(/^\s+/, "")
                .replace(/\s+$/, "");
            is_correct = ualc.search(ans) >= 0;
        }

        onChanged( user_answer, is_correct, answer_display );
    };

    const handleKeypress = event => {
        // Enter pressed? login
        if (event.charCode == 13) {
            handleSubmitAnswer();
        }
    };

    const handleChange = e => {
        const user_answer = e.target.value;

        //Update my info
        setState({ ...state, user_answer });
    };

    const klass = () => {
        if (response) {
            return response.correct
                ? " daily-questions__fitb-answer--correct"
                : " daily-questions__fitb-answer--incorrect";
        }

        return "";
    };

    return (
        <>
            <div className='daily-questions__fitb'>
                <textarea
                    type='text'
                    className={"daily-questions__fitb-answer" + klass()}
                    placeholder={Language.getSentenceCase('type answer here')}
                    disabled={response}
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                    value={response ? response.answer : user_answer}
                />
            </div>
            {response && !response.correct && (
                <div className='daily-questions__fitb-answer--correct'>
                    <i>{Language.getSentenceCase('correct answer')}: {answer_display}</i>
                </div>
            )}
        </>
    );
});
