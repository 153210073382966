import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { queueAudienceRaw } from "../../helpers/content";
import { SelectItems } from "../../components/select_items";
import {Drip7Dropdown} from "../../components/drip7_dropdown";

export const NotificationModal = (props) => {
    const { tenant_uid, showToast, onClose } = props

    let raw_notification = props.notification
    if (raw_notification == true || raw_notification == null || raw_notification == undefined) {
        raw_notification = {
            name: '',
            users: [],
            groups: [],
        };
    }

    const [state, setState] = useState({
        notification: raw_notification,
        selected_users: [],
        selected_groups: [],
        users: [],
        groups: [],
        show_audience: false,
    })
    const { notification, selected_users, selected_groups, users, groups, show_audience } = state

    useEffect(() => {
        //Get users and groups
        Util.fetch_js( "/human/list/", { tenant_uid },
            js => {
                const user_uids = (selected_users.length != 0)? selected_users: js.users.map( x => x.uid )

                setState(prev => ({ ...prev,
                    users: js.users,
                    selected_users: user_uids,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        Util.fetch_js( "/group/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    groups: js.groups,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }, [])

    const handleChecked = (e) => {
        let { queue } = state;
        queue[e.target.name] = e.target.checked;

        setState(prev => ({...prev, queue }))
    }

    const handleNotificationChange = (e) => {
        let notification = state.notification
        notification[e.target.name] = e.target.value

        setState(prev => ({ ...prev,
            notification
        }))
    }

    const handleChange = (e) => {
        setState(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleSave = () => {
        let { queue, selected_users, selected_groups, campaigns, schedules } = state

        const user_uids = (selected_users.length != users.length)? selected_users: []

        let payload = {
            ...queue,
            queue_uid: queue.uid,
            user_uids,
            group_uids: selected_groups,
            queue_track_uid: (queue_track.uid != null)? queue_track.uid: "",
            campaigns,
            schedules,
        };

        // Create?
        if (!("uid" in queue) || queue.uid == "") {
            payload['tenant_uid'] = tenant_uid
            Util.fetch_js( "/queue/create/", payload,
                js => {
                    showToast(Language.getSentenceCase('created queue'), 'successful');
                    onClose(true);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
        else {
            Util.fetch_js( "/queue/modify/", payload,
                js => {
                    showToast(Language.getSentenceCase('updated queue'), 'successful');
                    onClose(true);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
    }

    const showStyle = (show) => {
        return ( !show ) ? { rotate: '-90deg' }: {}
    }

    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('Notification')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <input
                        type='text'
                        className='basic-modal__input basic-modal__input--add-queue'
                        id='newQuestionCategory'
                        placeholder='Name'
                        name='name'
                        value={notification.name}
                        onChange={handleNotificationChange}
                    />
                </div>

                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('type')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        {/*}
                        <DropdownButton title={"Weekly"}>
                            {Object.entries(['Weekly', 'Daily']).map(([key, cur]) => (
                                <Dropdown.Item
                                    as='button'
                                    onClick={handleNotificationChange}
                                    name='curriculum_uid'
                                    value={cur.uid}
                                    key={"curriculum_uid_" + key}>
                                    {cur}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        {*/}
                    </div>
                </div>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question grid-item-row' style={{ maxHeight: 'unset' }}>
                    <label className='basic-modal__label'>
                        {Language.getTitleCase("settings")}
                    </label>

                    <div className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            aria-label="checkbox"
                            name='active'
                            readOnly
                            checked={notification.active}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({ target: { name: 'active', checked: !notification.active } })}>
                            {Language.getSentenceCase("Push")}
                        </label>
                    </div>

                    <div className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            aria-label="checkbox"
                            name='leaderboard'
                            readOnly
                            checked={notification.leaderboard}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({ target: { name: 'leaderboard', checked: !notification.leaderboard } })}>
                            {Language.getSentenceCase("Sms")}
                        </label>
                    </div>

                    <div className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            aria-label="checkbox"
                            name='refresh_yearly'
                            readOnly
                            checked={notification.refresh_yearly}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({ target: { name: 'refresh_yearly', checked: !notification.refresh_yearly } })}>
                            {Language.getSentenceCase('Email')}
                        </label>
                    </div>
                </div>
            </div>

            <div className='queue-grid'>
                <div className='basic-modal__field basic-modal__field--add-question grid-item--large'
                    onClick={() => handleChange({ target: { name: 'show_audience', value: !show_audience }})}>

                    <label className='basic-modal__label'>
                        {`${Language.getSentenceCase('current audience')}: ${queueAudienceRaw(selected_users.length, users.length, groups, selected_groups)}`}
                        &nbsp;
                        <i className='drop-chevron' style={ showStyle(show_audience) }></i>
                    </label>
                </div>
            </div>

            {show_audience && <>
                <SelectItems
                    selected={selected_users}
                    items={users}
                    title={"users"}
                    searchTitle={"search users by name"}
                    onChange={value => handleChange({ target: { name: 'selected_users', value }})}
                />

                <SelectItems
                    selected={selected_groups}
                    items={groups}
                    title={"groups"}
                    hideSearch={true}
                    hideAll={true}
                    onChange={value => handleChange({ target: { name: 'selected_groups', value }})}
                />
            </>}

            <div className='queue-grid'>
                <div className='basic-modal__field--add-question width-100'>
                    <label className='basic-modal__label' htmlFor='text'>
                        {Language.getTitleCase('message')}
                    </label>
                    <textarea
                        className='basic-modal__input basic-modal__input--add-question width-100'
                        placeholder={Language.getSentenceCase('Custom message to users')+'...'}
                        name='message'
                        onChange={handleChange}
                        value={message}></textarea>
                </div>
            </div>

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>
        </div>
    );
}
