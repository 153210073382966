import React, {useEffect, useState} from "react";

import { useStore } from "../../store";
import { AuthenticationType, Notification } from "../../helpers/consts";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Button } from "../../components/drip7_button";
import { Drip7Input } from "../../components/drip7_input";
import { Drip7Combobox } from "../../components/drip7_combobox";
import { Drip7Dropdown } from "../../components/drip7_dropdown";
import { Drip7Checkbox } from "../../components/drip7_checkbox";
import {
    ArrowPathIcon, DocumentDuplicateIcon, EyeIcon, EyeSlashIcon
} from "@heroicons/react/24/outline";


const LOGINS = {
    1: Language.getTitleCase('user/password'),
    2: Language.getTitleCase('azure sso'),
    3: Language.getTitleCase('saml'),
}


export const TenantSettingsTab = props => {
    const {
        initial_info,
        reload,
        changed,
        showToast,
        tenant_uid,
        tenants,
        onCreate,
        onSave,
        onRevert,
        onRemove,
        onChanged,
    } = props;

    const [state, setState] = useState({
        show_bearer: false,
        show_outlook: false,
        info: {...props.initial_info},
    })
    const {show_bearer, show_outlook} = state;
    const info = state.info || {}

    const login_title = LOGINS[info.auth_type];
    const public_leaderboard_url = `https://${info.subdomain}.drip7.com/leaderboard/${info.uid}/`

    const {usr_info} = useStore()

    useEffect(() => {
        //If is the same id, and the reload hasn't been changed, we don't want to change anything
        if ( !reload ) {
            return
        }

        //If the props inital_info is empty, don't change anything
        if (Object.keys(initial_info).length === 0) {
            return
        }

        //Setup my state info based on props
        const info = initAuthConfig({...initial_info})
        setState(prev => ({...prev, info}))
    }, [reload])

    const initAuthConfig = (info) => {
        // first check tenant_saml
        if (!info.tenant_saml.idp_entity_id) info.tenant_saml.idp_entity_id = '';
        if (!info.tenant_saml.idp_signon_url) info.tenant_saml.idp_signon_url = '';
        if (!info.tenant_saml.idp_logout_url) info.tenant_saml.idp_logout_url = '';
        if (!info.tenant_saml.idp_x509cert) info.tenant_saml.idp_x509cert = '';

        // now check on tenant_sso
        let {auth_config} = info.tenant_sso;

        if (!auth_config) auth_config = {};
        if (!auth_config.auth) auth_config.auth = {};
        if (!auth_config.auth.clientId) auth_config.auth.clientId = "";
        if (!auth_config.auth.authority)
            auth_config.auth.authority =
                "https://login.microsoftonline.com/{your_tenant_id}";
        if (!auth_config.auth.redirectUri)
            auth_config.auth.redirectUri =
                "https://" + info.subdomain + ".drip7.com";
        if (!auth_config.cache)
            auth_config.cache = {storeAuthStateInCookie: false};
        if (!auth_config.cache.cacheLocation)
            auth_config.cache.cacheLocation = "sessionStorage";
        info.tenant_sso.auth_config = auth_config;

        return info;
    }

    const handleSelectAuthentication = (e) => {
        const id = Util.xint(Object.keys(LOGINS).find(key => LOGINS[key] === e.target.value))

        let info = {
            ...state.info,
            auth_type: id
        };
        if (id === AuthenticationType.AZURE_SSO) {
            info = initAuthConfig(info);
        }
        setState(prev => ({...prev, info}))

        //Store the value
        onChanged(info);
    }

    const handleCopyClipboard = (url, msg) => {
        if ( url === '' ) {
            return
        }

        navigator.clipboard.writeText(url)
        showToast(msg, "successful")
    }

    const refreshBearer = () => {
        let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        info.bearer_token = ''
        for (let i=0; i<32; i=i+1) {
            info.bearer_token += chars.charAt(Math.floor(Math.random()*chars.length))
        }

        setState(prev => ({...prev,
            info
        }))

        handleCopyClipboard(info.bearer_token, "Copied Bearer Token")
        onChanged(info)
    }

    const handleInfoChanged = (e) => {
        if (e.target.id.startsWith('auth_')) {
            info.tenant_sso.auth_config.auth[e.target.id.replace('auth_', '')] = e.target.value
        }
        else if (e.target.id.startsWith('cache_')) {
            info.tenant_sso.auth_config.cache[e.target.id.replace('cache_', '')] = e.target.value
        }
        else if (e.target.id.startsWith('saml_')) {
            info.tenant_saml[e.target.id.replace('saml_', '')] = e.target.value
        }
        else {
            info[e.target.id] = e.target.value
        }

        //Store the value
        setState(prev => ({...prev, info}))
        onChanged(info);
    }

    const handleChanged = (e) => {
        setState(prev => ({...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handleChecked = (e) => {
        if (e.target.id === 'trial_remaining') {
            info[e.target.id] = e.target.checked ? 30 : null
        }
        else {
            info[e.target.id] = e.target.checked
        }

        if (e.target.id === 'public_leaderboard' && e.target.checked) {
            handleCopyClipboard(public_leaderboard_url, 'Copied URL')
        }

        //Store the value
        setState(prev => ({...prev, info}))
        onChanged(info)
    }

    const handleFetchManifest = () => {
        Util.fetch_js("/tenant/get_outlook_manifest/", {domain: info.subdomain},
            js => {
                window.open(js.url, '_blank');
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )
    }

    const getSCIMEndpoint = () => {
        const {tenant} = usr_info
        return 'https://' + tenant.subdomain + '.drip7.com/scim/v2/'
    }

    const getSAMLEntityID = () => {
        const {tenant} = usr_info
        return 'https://' + tenant.subdomain + '.drip7.com/saml/metadata/'
    }

    const getSAMLReplyURL = () => {
        const {tenant} = usr_info
        return 'https://' + tenant.subdomain + '.drip7.com/saml/acs/'
    }

    return (
        <div className="space-y-10 divide-y divide-gray-900/10 mt-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-4 lg:gap-y-8 sm:pr-8 lg:grid-cols-3">
                <div className="ml-4">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {Language.getTitleCase('main information')}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {Language.getSentenceCase('Main tenant information')}
                    </p>
                </div>

                <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ml-4 lg:ml-0">
                    <div className="px-4 py-6 sm:p-8">
                        <div
                            className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <Drip7Input
                                    name='company-name'
                                    label={Language.getTitleCase('company name')}
                                    placeholder={Language.getTitleCase('company name')}
                                    onChange={handleInfoChanged}
                                    id='name'
                                    value={info.name}
                                />
                            </div>

                            <div className="sm:col-span-4">
                                <Drip7Input
                                    name='subdomain'
                                    label={Language.getTitleCase('subdomain')}
                                    placeholder={Language.getTitleCase('subdomain')}
                                    onChange={handleInfoChanged}
                                    id='subdomain'
                                    value={info.subdomain}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-4 lg:gap-y-8 pt-10 sm:pr-8 lg:grid-cols-3">
                <div className="ml-4">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {Language.getTitleCase('support information')}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {Language.getSentenceCase('Contact information for support')}
                    </p>
                </div>

                <form
                    className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2 ml-4 lg:ml-0">
                    <div className="px-4 py-6 sm:p-8">
                        <div
                            className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <Drip7Input
                                    name='support-email'
                                    label={Language.getTitleCase('email')}
                                    placeholder={Language.getSentenceCase('email for support')}
                                    onChange={handleInfoChanged}
                                    id='support_email'
                                    value={info.support_email}
                                />
                            </div>

                            <div className="sm:col-span-4">
                                <Drip7Input
                                    name='support_email_name'
                                    label={Language.getTitleCase('friendly name')}
                                    placeholder={Language.getSentenceCase('friendly name')}
                                    onChange={handleInfoChanged}
                                    id='support_email_name'
                                    value={info.support_email_name}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Drip7Input
                                    type='tel'
                                    name='support-phone'
                                    label={Language.getTitleCase('phone')}
                                    placeholder='000-000-0000'
                                    id='support_phone'
                                    onChange={handleInfoChanged}
                                    value={info.support_phone}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Drip7Input
                                    name='support-text'
                                    label={Language.getTitleCase('SMS')}
                                    placeholder='000-000-0000'
                                    onChange={handleInfoChanged}
                                    id='support_sms'
                                    value={info.support_sms}
                                />
                            </div>

                            <div className="sm:col-span-5">
                                <Drip7Input
                                    name='support-url'
                                    label={Language.getTitleCase('Support url')}
                                    placeholder={'https://drip7.com/contact-us/'}
                                    onChange={handleInfoChanged}
                                    id='support_url'
                                    value={info.support_url}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div
                className="grid grid-cols-1 gap-x-8 gap-y-4 lg:gap-y-8 pt-10 sm:pr-8 lg:grid-cols-3">
                <div className="ml-4">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {Language.getTitleCase('authentication and provisioning')}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {Language.getSentenceCase('Configure login information, SCIM, SSO')}
                    </p>
                </div>

                <form
                    className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2 ml-4 lg:ml-0">
                    <div className="px-4 py-6 sm:p-8">
                        <div
                            className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label className="block text-sm font-normal leading-6 text-gray-700">
                                    {Language.getTitleCase( 'Provisioning' )}
                                </label>
                            </div>

                            <div className="sm:col-span-5">
                                <Drip7Input
                                    id='scim_endpoint'
                                    name='scim-endpoint'
                                    disabled
                                    label={"SCIM " + Language.getTitleCase( 'endpoint' )}
                                    className='disabled:cursor-pointerSlot'
                                    value={getSCIMEndpoint()}
                                >
                                    &nbsp;
                                    <DocumentDuplicateIcon
                                        className="h-5 w-5 text-drip7 cursor-pointer"
                                        onClick={() => handleCopyClipboard( getSCIMEndpoint(), 'Copied SCIM Endpoint' )}/>
                                </Drip7Input>
                            </div>

                            <div className="sm:col-span-5">
                                <Drip7Input
                                    id='show_bearer'
                                    name='show_bearer'
                                    disabled
                                    type={show_bearer || info.bearer_token === '' ? 'text' : 'password'}
                                    label={Language.getTitleCase( 'bearer token' )}
                                    className='disabled:cursor-pointerSlot'
                                    value={info.bearer_token === ''? '**empty**': info.bearer_token}
                                    onClick={() => handleCopyClipboard( info.bearer_token, "Copied Bearer Token" )}
                                >
                                    <>
                                    {!show_bearer &&
                                        <EyeSlashIcon
                                            className="h-5 w-5 text-drip7 cursor-pointer"
                                            onClick={() => handleChanged( {
                                                target: {
                                                    id: 'show_bearer',
                                                    value: !show_bearer
                                                }
                                            } )}/>
                                    }
                                    {show_bearer &&
                                        <EyeIcon
                                            className="h-5 w-5 text-drip7 cursor-pointer"
                                            onClick={() => handleChanged( {
                                                target: {
                                                    id: 'show_bearer',
                                                    value: !show_bearer
                                                }
                                            } )}/>
                                        }
                                    </>
                                    <DocumentDuplicateIcon
                                        className={Util.classNames(
                                            "h-5 w-5",
                                            info.bearer_token !== ''? 'text-drip7 cursor-pointer': 'text-gray-500'
                                        )}
                                        onClick={() => handleCopyClipboard( info.bearer_token, 'Copied Bearer Token' )}/>
                                </Drip7Input>
                            </div>

                            <div className="col-span-1 sm:mt-9">
                                <ArrowPathIcon
                                    className="h-5 w-5 text-drip7 cursor-pointer"
                                    onClick={refreshBearer}/>
                            </div>
                            <div className="sm:col-span-5">
                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <Drip7Checkbox
                                            type='checkbox'
                                            aria-label="checkbox"
                                            name='users-can-edit'
                                            id='scim_welcomeemail'
                                            onChange={handleChecked}
                                            checked={info.scim_welcomeemail}
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="scim_welcomeemail"
                                                className="font-medium text-gray-900">
                                            {Language.getTitleCase('welcome email')}
                                        </label>
                                        <p className="text-gray-500">
                                            {Language.getTitleCase('provisioned users receive a welcome email')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mt-8 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 lg:gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="auth_type"
                                       className="block text-sm font-normal leading-6 text-gray-700">
                                    {Language.getTitleCase( 'authentication' )}
                                </label>
                                <Drip7Dropdown
                                    name='auth_type'
                                    label={Language.getTitleCase( 'authentication' )}
                                    onChange={handleSelectAuthentication}
                                    id='auth_type'
                                    value={login_title}
                                    entries={Object.values( LOGINS )}
                                />
                            </div>

                            {info.auth_type == AuthenticationType.AZURE_SSO && (<>
                                <div className="sm:col-span-3">
                                    <Drip7Input
                                        name='client-id'
                                        label={Language.getTitleCase( 'client id' )}
                                        placeholder={Language.getSentenceCase( 'application (client) id' )}
                                        onChange={handleInfoChanged}
                                        id='auth_clientId'
                                        value={info.tenant_sso.auth_config?.auth?.clientId}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <Drip7Input
                                        name='auth-authority'
                                        label={Language.getTitleCase('authority')}
                                        placeholder='https://login.microsoftonline.com/{your_tenant_id}'
                                        id='auth_authority'
                                        onChange={handleInfoChanged}
                                        value={info.tenant_sso.auth_config?.auth?.authority}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <Drip7Input
                                        name='redirect-uri'
                                        label={Language.getTitleCase('redirect uri')}
                                        placeholder='https://play.drip7.com'
                                        onChange={handleInfoChanged}
                                        id='auth_redirectUri'
                                        value={info.tenant_sso.auth_config?.auth?.redirectUri}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <Drip7Input
                                        name='auth-cache-location'
                                        label={Language.getTitleCase('cache location')}
                                        placeholder={'sessionStorage'}
                                        onChange={handleInfoChanged}
                                        id='cache_cacheLocation'
                                        value={info.tenant_sso.auth_config?.cache?.cacheLocation}
                                    />
                                </div>

                                <div className="sm:col-span-6">
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                name='auth-store-cookie'
                                                label={Language.getTitleCase( 'store cookie' )}
                                                onChange={e => handleInfoChanged( {
                                                    target: {
                                                        id: 'cache_storeAuthStateInCookie',
                                                        value: e.target.checked
                                                    }
                                                } )}
                                                id='cache_storeAuthStateInCookie'
                                                checked={info.tenant_sso.auth_config?.cache?.storeAuthStateInCookie}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="cache_storeAuthStateInCookie"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase( 'store cookie' )}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase( "Cache the cookie locally to auto sign-in" )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                            {info.auth_type == AuthenticationType.SAML && (<>
                                <div className="sm:col-span-5">
                                    <Drip7Input
                                        id='saml_sp_entityid'
                                        name='saml-sp_entityid'
                                        disabled
                                        label={"SAML " + Language.getTitleCase( 'entity id' )}
                                        className='disabled:cursor-pointerSlot'
                                        value={getSAMLEntityID()}
                                    >
                                        &nbsp;
                                        <DocumentDuplicateIcon
                                            className="h-5 w-5 text-drip7 cursor-pointer"
                                            onClick={() => handleCopyClipboard( getSAMLEntityID(), 'Copied SAML Entity ID' )}/>
                                    </Drip7Input>
                                </div>
                                <div className="sm:col-span-5">
                                    <Drip7Input
                                        id='saml_replyurl'
                                        name='saml-replyurl'
                                        disabled
                                        label={"SAML " + Language.getTitleCase( 'reply url' )}
                                        className='disabled:cursor-pointerSlot'
                                        value={getSAMLReplyURL()}
                                    >
                                        &nbsp;
                                        <DocumentDuplicateIcon
                                            className="h-5 w-5 text-drip7 cursor-pointer"
                                            onClick={() => handleCopyClipboard( getSAMLReplyURL(), 'Copied SAML Reply URL' )}/>
                                    </Drip7Input>
                                </div>
                                <div className="sm:col-span-6">
                                    <Drip7Input
                                        name='idp-entity-id'
                                        label={Language.getTitleCase( 'idp entity id' )}
                                        placeholder={Language.getSentenceCase( 'idp entity id' )}
                                        onChange={handleInfoChanged}
                                        id='saml_idp_entity_id'
                                        value={info.tenant_saml?.idp_entity_id}
                                    />
                                </div>

                                <div className="sm:col-span-6">
                                    <Drip7Input
                                        name='idp-signon-url'
                                        label={Language.getTitleCase('idp signon url')}
                                        placeholder='https://{idpsignon}'
                                        id='saml_idp_signon_url'
                                        onChange={handleInfoChanged}
                                        value={info.tenant_saml?.idp_signon_url}
                                    />
                                </div>

                                <div className="sm:col-span-6">
                                    <Drip7Input
                                        name='idp-logout-url'
                                        label={Language.getTitleCase('idp logout url')}
                                        placeholder='https://{idplogouturl}'
                                        onChange={handleInfoChanged}
                                        id='saml_idp_logout_url'
                                        value={info.tenant_saml?.idp_logout_url}
                                    />
                                </div>

                                <div className="sm:col-span-6">
                                    <textarea
                                        className='basic-modal__input basic-modal__input--add-question width-100'
                                        style={{ height: 400 }}
                                        placeholder={Language.getSentenceCase('idp x509cert')}
                                        onChange={handleInfoChanged}
                                        id='saml_idp_x509cert'
                                        value={info.tenant_saml?.idp_x509cert}
                                    />
                                </div>

                                <div className="sm:col-span-6">
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                name='saml_add_new_user'
                                                label={Language.getTitleCase( 'add new user' )}
                                                onChange={e => handleInfoChanged( {
                                                    target: {
                                                        id: 'saml_add_new_user',
                                                        value: e.target.checked
                                                    }
                                                } )}
                                                id='saml_add_new_user'
                                                checked={info.tenant_saml?.add_new_user}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="saml_add_new_user"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase( 'add new user' )}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase( "add users to drip7 database automatically" )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                </form>
            </div>


            <div
                className="grid grid-cols-1 gap-x-8 gap-y-4 lg:gap-y-8 pt-10 pb-10 sm:pr-8 lg:grid-cols-3">
                <div className="ml-4">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {Language.getTitleCase( 'settings' )}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {Language.getSentenceCase( 'Notifications and tenant configuration' )}
                    </p>
                </div>

                <form
                    className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2 ml-4 lg:ml-0">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="max-w-2xl space-y-10">
                            <fieldset>
                                <legend
                                    className="text-sm font-semibold leading-6 text-gray-900">
                                    {Language.getTitleCase( 'users' )}
                                </legend>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    {Language.getSentenceCase( 'User settings and behavior for all users' )}
                                </p>
                                <div className="mt-6 space-y-6">
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                name='can-register-tagline'
                                                label={Language.getTitleCase('Allow anyone to register')}
                                                onChange={handleChecked}
                                                id='can_register'
                                                checked={info.can_register}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="can_register"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('public registration')}
                                            </label>
                                            {(!info.can_register || tenants.length <= 0) &&
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase('Allow anyone to register')}
                                            </p>
                                            }
                                            {info.can_register && tenants.length >= 1 &&
                                                <div className='dropdown dropdown--attached'>
                                                    <p className="text-gray-500">
                                                        {Language.getSentenceCase('Select a demo tenant for registration')}
                                                    </p>
                                                    <Drip7Combobox
                                                        id="demo_tenant_uid"
                                                        entries={[{name: Language.getTitleCase('Use my tenant'), uid: ''}].concat(tenants.filter(x => x.uid !== tenant_uid))}
                                                        selected_idx={Math.max(tenants.findIndex(x => x.uid === info.demo_tenant_uid), 0)}
                                                        onEntry={x => handleInfoChanged({target: {id: 'demo_tenant_uid', value: x.uid}})}
                                                        />
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name='users-can-edit'
                                                id='user_editing'
                                                onChange={handleChecked}
                                                checked={info.user_editing}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="user_editing"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('user edit')}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getTitleCase('Users can edit their information')}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name='users-can-delete'
                                                readOnly
                                                id='allow_delete'
                                                onChange={handleChecked}
                                                checked={info.allow_delete}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="allow_delete"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('user delete')}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase("allow users to completely delete their account and data")}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name='allow_anonymity'
                                                readOnly
                                                id='allow_anonymity'
                                                onChange={handleChecked}
                                                checked={info.allow_anonymity}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="allow_anonymity"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('allow leaderboard anonymity')}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase("users can optionally be anonymous on the leaderboard")}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name='reset_points_yearly'
                                                readOnly
                                                id='reset_points_yearly'
                                                onChange={handleChecked}
                                                checked={info.reset_points_yearly}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="reset_points_yearly"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('reset points yearly')}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase("resets all users points to zero annually")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend
                                    className="text-sm font-semibold leading-6 text-gray-900">
                                    {Language.getTitleCase('tenant')}
                                </legend>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    {Language.getSentenceCase('Tenant settings and behavior')}
                                </p>
                                <div className="mt-6 space-y-6">
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                id='public_leaderboard'
                                                name='public-leaderboard'
                                                onChange={handleChecked}
                                                checked={info.public_leaderboard}
                                                aria-label='public-leaderboard'

                                            />
                                        </div>
                                        <div
                                            className="text-sm leading-6 w-100">
                                            <label htmlFor="public_leaderboard"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('public leaderboard')}
                                            </label>
                                            {info.public_leaderboard && (
                                                <Drip7Input
                                                    id='show_bearer'
                                                    name='show_bearer'
                                                    disabled
                                                    type='text'
                                                    className='disabled:cursor-pointerSlot'
                                                    value={public_leaderboard_url}
                                                    onClick={() => handleCopyClipboard(public_leaderboard_url)}
                                                >
                                                    &nbsp;
                                                    <DocumentDuplicateIcon
                                                        className="h-5 w-5 text-drip7 cursor-pointer"
                                                        onClick={() => handleCopyClipboard(public_leaderboard_url, 'Copied URL')}/>
                                                </Drip7Input>
                                            )}
                                            {!info.public_leaderboard && (
                                                <p className="text-gray-500">
                                                    {Language.getSentenceCase("public link anyone can use to view the leaderboard without a user account")}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name='trial_remaining'
                                                id='trial_remaining'
                                                onChange={handleChecked}
                                                checked={info.trial_remaining != null}
                                                readOnly
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="trial_remaining"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('30-day trial')}
                                            </label>
                                            <p className="text-gray-500">
                                                {Language.getSentenceCase("when checked, the tenant is on a 30-day trial")}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <Drip7Checkbox
                                                type='checkbox'
                                                id='show_outlook'
                                                onChange={e => handleChanged( {target:{ id: e.target.id, value: e.target.checked}} )}
                                                checked={show_outlook}
                                                aria-label='show-outlook'

                                            />
                                        </div>
                                        <div
                                            className="text-sm leading-6 w-100">
                                            <label htmlFor="show_outlook"
                                                   className="font-medium text-gray-900">
                                                {Language.getTitleCase('Generate outlook manifest')}
                                            </label>
                                            {show_outlook && (
                                                <Drip7Button
                                                    onClick={handleFetchManifest}>
                                                    {Language.getTitleCase("Outlook phish extension manifest")}
                                                </Drip7Button>
                                            )}
                                            {!show_outlook && (
                                                <p className="text-gray-500">
                                                    {Language.getSentenceCase("Generate a manifest for the outlook extension")}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    { !changed &&
                        <div className="flex items-center justify-content-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            {usr_info.tenant.uid !== info.uid &&
                            <Drip7Button
                                id='discard_changes'
                                mode="outlined"
                                color=""
                                className="text-red-600 ring-red-600"
                                onClick={onRemove}>
                                {Language.getTitleCase( 'remove tenant' )}
                            </Drip7Button>
                            }
                            {usr_info.tenant.uid === info.uid &&
                                <div></div>
                            }
                            <Drip7Button
                                id='save_changes'
                                onClick={onCreate}>
                                {Language.getTitleCase( 'create tenant' )}
                            </Drip7Button>
                        </div>
                    }
                    {changed &&
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <Drip7Button
                                id='discard_changes'
                                mode="outlined"
                                onClick={onRevert}>
                                {Language.getTitleCase( 'discard changes' )}
                            </Drip7Button>
                            <Drip7Button
                                id='save_changes'
                                onClick={() => onSave( info )}>
                                {Language.getTitleCase( 'save changes' )}
                            </Drip7Button>
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}
