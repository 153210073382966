import React, { useEffect, useState } from "react";

export const AnswerTypeInlineArea = (props) => {
    const { name, value, onChanged } = props;

    return (
        <div className='daily-questions__fitb'>
            <textarea
                type='text'
                className="daily-questions__fitb-answer"
                placeholder='Type Answer Here'
                name={name}
                value={value}
                onChange={onChanged}
            />
        </div>
    );
}
