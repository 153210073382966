import React, {useState} from "react";
import {Drip7Dropdown} from "../drip7_dropdown";
import Language from "../../helpers/language";

import {buildQuestions, filterQuestions} from "../../helpers/content";
import {AnswerType, ContentType} from "../../helpers/consts";
import {QuestionTableWidget} from "./question_table_widget";

export const CurriculumSpecificWidget = props => {
    const { title, onAdd, onRemove, categories, question_uids, show_hide } = props;

    const [state, setState] = useState({
        search: "",
        category_uid: "",
        content_type: "",
        answer_type: "",
        apply_filter: false,
        show_questions: question_uids.length > 0 || show_hide || show_hide == null || show_hide == undefined,
    });
    const { search, category_uid, apply_filter, content_type, answer_type } = state;
    const show_questions = state.show_questions | question_uids.length > 0

    const questions = buildQuestions( categories )
    let filtered_questions = filterQuestions(
        questions,
        search,
        category_uid,
        content_type,
        answer_type,
    );

    const cat_title = (categories.find( x => x.uid === category_uid ) || { name: "Categories", uid: ''})
    const cat_entries = [{uid: '', name: '---'}].concat(
        Object.entries(categories).sort((c1, c2) => { return c1[1].name.localeCompare(c2[1].name); }).map(x => x[1]))

    //Give friendly names to content and answers that can be anything
    const ctt = (content_type == null || content_type == undefined || content_type == "")? {name: "Content", value: ""}: {name: Language.getTitleCase(content_type), value: content_type}
    const att = (answer_type == null || answer_type == undefined || answer_type == "")? {name: 'Answer', value: ''}: {name: Language.getTitleCase(answer_type), value: answer_type}

    const lookup = {}
    if (question_uids.length > 0) {
        question_uids.forEach( x => {
            lookup[x] = true
        })
    }

    //Add my selected questions
    const sel_q = apply_filter ? filtered_questions : questions;
    let selected_questions = [];
    for (let i = sel_q.length - 1; i >= 0; i--) {
        if (sel_q[i].uid in lookup) {
            selected_questions.push(sel_q[i]);
        }
    }

    //Remove filtered questions that are already selected
    for (let i = filtered_questions.length - 1; i >= 0; i--) {
        if (filtered_questions[i].uid in lookup) {
            filtered_questions.splice(i, 1);
        }
    }

    const handleChange = e => {
        setState( prev => ({ ...prev, [e.target.id]: e.target.value }))
    }

    const handleChecked = e => {
        setState( prev => ({ ...prev, [e.target.id]: e.target.checked }))
    }

    const showStyle = (show) => {
        return ( !show ) ? { rotate: '-90deg' }: {}
    }

    const handleToggle = () => {
        if ( show_hide == true || show_hide == false ) {
            setState( prev => ({ ...prev, show_questions: !show_questions }) )
        }
    }

    return (
        <div className='basic-modal__config'>
            <label className='basic-modal__label' htmlFor='text'
                   onClick={handleToggle}>
                {title}
                &nbsp;
                {(show_hide == true || show_hide == false) &&
                    <i className='drop-chevron' style={ showStyle(show_questions) }>
                    </i>
                }
            </label>
            {show_questions == true && <>
            <div className='central__filters'>
                <h3 className="basic-modal__header">
                    {Language.getSentenceCase('possible')} ({filtered_questions.length})
                </h3>
                <div className="buttons">
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='category_uid'
                            name='category_uid'
                            value={cat_title}
                            entries={cat_entries}
                            display={x => x.name}
                            overflow={'max-h-60'}
                            onChange={e => handleChange({ target: { id: 'category_uid', value: e.target.value.uid }})}
                            />
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='content_type'
                            name='content_type'
                            value={ctt}
                            entries={[{name: '---', value: ''}].concat(Object.values(ContentType).map(ct => ({name: ct, value: Language.getTitleCase(ct)})))}
                            display={x => x.name}
                            onChange={x => handleChange({ target: { id: 'content_type', value: x.target.value.value }})}
                            overflow={'max-h-60'}
                            />
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='answer_type'
                            name='answer_type'
                            value={att}
                            entries={[{name: '---', value: ''}].concat(Object.values(AnswerType).map(at => ({name: at, value: Language.getTitleCase(at)})))}
                            display={x => x.name}
                            onChange={x => handleChange({ target: { id: 'answer_type', value: x.target.value.value }})}
                            overflow={'max-h-60'}
                            />
                    </div>
                </div>

                <div className='search-bar-container'>
                    <i className='icon search-icon'></i>
                    <input
                        className='search-input'
                        type='search'
                        placeholder={Language.getSentenceCase('search for questions')}
                        id='search'
                        value={search}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className='table__wrapper'>
                <QuestionTableWidget
                    show_selection={false}
                    show_answers={false}
                    show_tips={false}
                    questions={filtered_questions}
                    onQuestionClick={onAdd}
                    sortable={false}
                />
            </div>

            <div className="header__wrapper">
                <h3 className="basic-modal__header">
                    {Language.getSentenceCase('selected')} ({selected_questions.length})
                </h3>
                <div className="customization-checkbox">
                    <div className='d7__checkbox-container'>
                        <input
                            className='d7__checkbox'
                            aria-label="checkbox"
                            type='checkbox'
                            id='apply_filter'
                            checked={apply_filter}
                            onChange={handleChecked}
                        />
                        <span className='checkmark'></span>
                    </div>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getSentenceCase('apply filter')}
                    </label>
                </div>
            </div>
            <div className='table__wrapper'>
                <QuestionTableWidget
                    show_selection={false}
                    show_tips={false}
                    show_answers={false}
                    questions={selected_questions}
                    onQuestionClick={onRemove}
                    sortable={false}
                />
            </div>
            </>}
        </div>
    );
};
