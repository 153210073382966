import React, { useState } from "react";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7OriginalModal } from "../../modals/drip7_original_modal";
import { Drip7Button } from "../../components/drip7_button";
import { TenantModal } from "../../modals/admin/tenant_model";
import { TenantSettingsTab } from "./tenant_settings_tab";
import { TenantCustomizeTab } from "./tenant_customize_tab";
import { TenantNavigationTab } from "./tenant_navigation_tab";
import {TenantSubscriptionTab} from "./tenant_subscription_tab";
import {PresentationChartLineIcon} from "@heroicons/react/24/outline";
import {TenantPresentationTab} from "./tenant_presentation_tab";

//State wrapper
const withStore = BaseComponent => props => {
    const { setUsrInfo, usr_info } = useStore(state => state)
    return <BaseComponent {...props}
                          setUsrInfo={setUsrInfo}
                          usr_info={usr_info}
    />;
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            selected_idx: 0,
            info: {},
            reload: 0,
            show_create: false
        };

        this.handleChanged = this.handleChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleRevert = this.handleRevert.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handlePresentation = this.handlePresentation.bind(this);
    }

    componentDidMount() {
        this.props.onPathChange("tenants");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tenant_uid != this.props.tenant_uid) {
        }
    }

    handleChanged(info) {
        this.setState({ changed: true, info });
    }

    handleRevert() {
        const reload = this.state.reload + 1;
        //On revert, go back to settings tab so we can reload the customize tab easily
        this.setState({ changed: false, reload });

        //revert to original colors
        document.documentElement.style.setProperty(
            "--drip7-primary-color",
            this.props.usr_info.tenant.primary_color
        );
        document.documentElement.style.setProperty(
            "--drip7-secondary-color",
            this.props.usr_info.tenant.secondary_color
        );
    }

    handlePresentation() {
        const { tenant_uid, showToast } = this.props

        showToast(Language.getTitleCase("Starting update"), "info" )
        Util.fetch_js('/tenant/update_presentation/', { tenant_uid },
            js => {
                showToast(Language.getTitleCase("Successfully updated"), "successful" )
            },
            (err, code) => {
                showToast( err, "failure" )
            })
    }

    handleSave( tenant ) {
        const { usr_info, tenant_uid, tenants, setUsrInfo, showToast, onRefreshMspsTenants } = this.props;
        const { reload } = this.state;

        // Set the demo property based on trial_remaining
        const info = {...tenant}
        info['demo'] = info['trial_remaining'] != null;

        //Write out the changes
        Util.fetch_js( "/tenant/modify/", { ...info, tenant_uid },
            js => {
                showToast(Language.getTitleCase('update successful'), 'successful');
                onRefreshMspsTenants(() => {
                    this.setState({ changed: false, reload: reload + 1, info: js });

                    //Force refresh of tenant data
                    if ( usr_info.tenant_uid == tenant_uid ) {
                        Util.fetch_js( "/human/is_valid/", {},
                            js => {
                                //Store some user info
                                if (js.valid) {
                                    const my_data = {
                                        ...js.usr_info,
                                        msp: js.msp,
                                        tenant: js.tenant
                                    };
                                    setUsrInfo(my_data);
                                }
                            },
                            (err, code) => {
                            })
                    }
                })
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    handleCreate() {
        this.setState({...this.state, show_create: true})
    }

    handleRemove() {
        const { tenant_uid, tenants, showToast, onRefreshMspsTenants } =
            this.props;

        //Find the tenant
        const info = tenants.find(x => x.uid == tenant_uid) || null;
        if (info == null) {
            showToast(Language.getSentenceCase('Couldn\'t find currently selected tenant'), 'error');
            return;
        }

        //Request the name of the tenant
        const name = prompt(Language.getSentenceCase('enter tenant name'));

        if (name == info.name) {
            Util.fetch_js( "/tenant/remove/", { tenant_uid },
                js => {
                    showToast(Language.getSentenceCase('successfully removed') + " " + name, 'successful');
                    onRefreshMspsTenants();
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }
        else if (name != "" && name != null) {
            showToast(name + " != " + info.name, 'failure');
        }
    }

    handleCloseModal(js) {
        const { onContext } = this.props;
        const { onRefreshMspsTenants } = this.props;

        if (js) {
            onRefreshMspsTenants()
            onContext( js.uid )
            this.setState({...this.state, changed: false, show_create: false, info: js });
        }
        else
            this.setState({...this.state, show_create: false})
    }

    render() {
        const { usr_info, tenant_uid, tenants, showToast } = this.props;
        const { changed, selected_idx, reload, show_create } = this.state;

        const initial_info = tenants.find(x => x.uid == tenant_uid) || {};

        return (
            <div className='main-modal customization-menu'>
                <div className='customization-header'>
                    <nav className='customization-nav'>
                        <ul role='tablist'>
                            <li className={selected_idx == 0 ? "selected" : ""}
                                role='tab'
                                aria-selected={selected_idx == 0}
                                aria-controls='tab_panel_design_settings'
                                onClick={() =>
                                    this.setState({selected_idx: 0})
                                }>
                                <svg
                                    width='15'
                                    height='16'
                                    viewBox='0 0 15 16'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M13.3301 0.875C12.8613 0.875 12.4219 1.08008 12.0996 1.40234C5.71289 7.08594 4.95117 7.23242 5.24414 9.13672C4.04297 9.2832 2.95898 9.86914 2.31445 11.5391C2.22656 11.7441 2.05078 11.8613 1.8457 11.8613C1.46484 11.8613 0.322266 10.9531 0 10.7188C0 13.4727 1.25977 15.875 4.27734 15.875C7.70508 15.875 8.73047 13.3262 8.52539 11.627C10.166 11.4219 11.3672 9.78125 14.5605 3.80469C14.7949 3.36523 15 2.89648 15 2.39844C15 1.51953 14.1797 0.875 13.3301 0.875ZM6.50391 13.5605C6.00586 14.1172 5.27344 14.3809 4.27734 14.3809C3.22266 14.3809 2.54883 13.9414 2.13867 13.3555C2.8418 13.2383 3.45703 12.7695 3.7207 12.0664C4.13086 11.0117 4.6875 10.7188 5.44922 10.6309L5.91797 10.5723L6.97266 11.4219L7.03125 11.8027C7.08984 12.2715 6.97266 13.0039 6.50391 13.5605ZM8.32031 10.1328L7.82227 10.1914L6.82617 9.37109L6.73828 8.93164C6.5625 7.73047 13.0078 2.19336 13.3594 2.48633C13.7109 2.7793 9.78516 9.95703 8.32031 10.1328Z'
                                        fill='currentColor'></path>
                                </svg>
                                {Language.getTitleCase('design settings')}
                            </li>
                            <li className={selected_idx == 1 ? "selected" : ""}
                                role='tab'
                                aria-selected={selected_idx == 1}
                                aria-controls='tab_panel_main_information'
                                onClick={() => this.setState({selected_idx: 1})}>
                                <svg
                                    width='17'
                                    height='14'
                                    viewBox='0 0 17 14'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M15.5312 0.8125H1.46875C0.677734 0.8125 0.0625 1.45703 0.0625 2.21875V12.5312C0.0625 13.3223 0.677734 13.9375 1.46875 13.9375H15.5312C16.293 13.9375 16.9375 13.3223 16.9375 12.5312V2.21875C16.9375 1.45703 16.293 0.8125 15.5312 0.8125ZM15.5312 12.5312H1.46875V2.21875H15.5312V12.5312ZM6.15625 7.375C7.18164 7.375 8.03125 6.55469 8.03125 5.5C8.03125 4.47461 7.18164 3.625 6.15625 3.625C5.10156 3.625 4.28125 4.47461 4.28125 5.5C4.28125 6.55469 5.10156 7.375 6.15625 7.375ZM3.51953 11.125H8.76367C9.14453 11.125 9.4375 10.8906 9.4375 10.5684V10.0117C9.4375 9.07422 8.5293 8.3125 7.44531 8.3125C7.15234 8.3125 6.91797 8.54688 6.15625 8.54688C5.36523 8.54688 5.16016 8.3125 4.83789 8.3125C3.75391 8.3125 2.875 9.07422 2.875 10.0117V10.5684C2.875 10.8906 3.16797 11.125 3.51953 11.125ZM10.6094 9.25H13.8906C14.0078 9.25 14.125 9.16211 14.125 9.01562V8.54688C14.125 8.42969 14.0078 8.3125 13.8906 8.3125H10.6094C10.4629 8.3125 10.375 8.42969 10.375 8.54688V9.01562C10.375 9.16211 10.4629 9.25 10.6094 9.25ZM10.6094 7.375H13.8906C14.0078 7.375 14.125 7.28711 14.125 7.14062V6.67188C14.125 6.55469 14.0078 6.4375 13.8906 6.4375H10.6094C10.4629 6.4375 10.375 6.55469 10.375 6.67188V7.14062C10.375 7.28711 10.4629 7.375 10.6094 7.375ZM10.6094 5.5H13.8906C14.0078 5.5 14.125 5.41211 14.125 5.26562V4.79688C14.125 4.67969 14.0078 4.5625 13.8906 4.5625H10.6094C10.4629 4.5625 10.375 4.67969 10.375 4.79688V5.26562C10.375 5.41211 10.4629 5.5 10.6094 5.5Z'
                                        fill='currentColor'></path>
                                </svg>
                                {Language.getTitleCase('Subscription')}
                            </li>
                            <li className={selected_idx == 2 ? "selected" : ""}
                                role='tab'
                                aria-selected={selected_idx == 2}
                                aria-controls='tab_panel_main_information'
                                onClick={() => this.setState({selected_idx: 2})}>
                                <svg
                                    width='17'
                                    height='14'
                                    viewBox='0 0 17 14'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M15.5312 0.8125H1.46875C0.677734 0.8125 0.0625 1.45703 0.0625 2.21875V12.5312C0.0625 13.3223 0.677734 13.9375 1.46875 13.9375H15.5312C16.293 13.9375 16.9375 13.3223 16.9375 12.5312V2.21875C16.9375 1.45703 16.293 0.8125 15.5312 0.8125ZM15.5312 12.5312H1.46875V2.21875H15.5312V12.5312ZM6.15625 7.375C7.18164 7.375 8.03125 6.55469 8.03125 5.5C8.03125 4.47461 7.18164 3.625 6.15625 3.625C5.10156 3.625 4.28125 4.47461 4.28125 5.5C4.28125 6.55469 5.10156 7.375 6.15625 7.375ZM3.51953 11.125H8.76367C9.14453 11.125 9.4375 10.8906 9.4375 10.5684V10.0117C9.4375 9.07422 8.5293 8.3125 7.44531 8.3125C7.15234 8.3125 6.91797 8.54688 6.15625 8.54688C5.36523 8.54688 5.16016 8.3125 4.83789 8.3125C3.75391 8.3125 2.875 9.07422 2.875 10.0117V10.5684C2.875 10.8906 3.16797 11.125 3.51953 11.125ZM10.6094 9.25H13.8906C14.0078 9.25 14.125 9.16211 14.125 9.01562V8.54688C14.125 8.42969 14.0078 8.3125 13.8906 8.3125H10.6094C10.4629 8.3125 10.375 8.42969 10.375 8.54688V9.01562C10.375 9.16211 10.4629 9.25 10.6094 9.25ZM10.6094 7.375H13.8906C14.0078 7.375 14.125 7.28711 14.125 7.14062V6.67188C14.125 6.55469 14.0078 6.4375 13.8906 6.4375H10.6094C10.4629 6.4375 10.375 6.55469 10.375 6.67188V7.14062C10.375 7.28711 10.4629 7.375 10.6094 7.375ZM10.6094 5.5H13.8906C14.0078 5.5 14.125 5.41211 14.125 5.26562V4.79688C14.125 4.67969 14.0078 4.5625 13.8906 4.5625H10.6094C10.4629 4.5625 10.375 4.67969 10.375 4.79688V5.26562C10.375 5.41211 10.4629 5.5 10.6094 5.5Z'
                                        fill='currentColor'></path>
                                </svg>
                                {Language.getTitleCase('Main Information')}
                            </li>
                            <li className={selected_idx == 3 ? "selected" : ""}
                                role='tab'
                                aria-selected={selected_idx == 3}
                                aria-controls='tenant-navigation'
                                onClick={() => this.setState({selected_idx: 3})}>
                                <svg width="15" height="15" viewBox="0 0 48 48"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z"
                                        fill='currentColor'/>
                                </svg>
                                {Language.getTitleCase('Navigation')}
                            </li>
                            {initial_info.presentation &&
                            <li className={selected_idx == 4 ? "selected" : ""}
                                role='tab'
                                aria-selected={selected_idx == 4}
                                aria-controls='tenant-navigation'
                                onClick={() => this.setState({selected_idx: 4})}>
                                <PresentationChartLineIcon className="h-6 w-6" />
                                {Language.getTitleCase('Presentation')}
                            </li>
                            }
                        </ul>
                    </nav>

                    {false && !changed && selected_idx == 2 && (
                        <div className='customization-save-buttons'>
                            {initial_info.presentation &&
                                <Drip7Button
                                    onClick={this.handlePresentation}>
                                    {Language.getTitleCase('Update Presentation')}
                                </Drip7Button>
                            }

                            {usr_info.tenant_uid != tenant_uid &&
                                <Drip7Button
                                    name="remove-user"
                                    color='red-500'
                                    onClick={this.handleRemove}>
                                    {Language.getTitleCase('remove tenant')}
                                </Drip7Button>
                            }

                            <Drip7Button
                                onClick={this.handleCreate}>
                                {Language.getTitleCase('create tenant')}
                            </Drip7Button>
                        </div>
                    )}

                    {false && changed && (
                        <div className='customization-save-buttons'>
                            <Drip7Button
                                mode='outlined'
                                onClick={this.handleRevert}>
                                {Language.getTitleCase('discard changes')}
                            </Drip7Button>
                            <Drip7Button
                                onClick={this.handleSave}>
                                {Language.getTitleCase('Save Changes')}
                            </Drip7Button>
                        </div>
                    )}
                </div>

                {selected_idx == 0 && (
                    <TenantCustomizeTab
                        initial_info={initial_info}
                        tenant_uid={tenant_uid}
                        reload={reload}
                        changed={changed}
                        showToast={showToast}
                        onChanged={this.handleChanged}
                        onSave={this.handleSave}
                        onRevert={this.handleRevert}
                    />
                )}
                {selected_idx == 1 && (
                    <TenantSubscriptionTab
                        initial_info={initial_info}
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        reload={reload}
                        showToast={showToast}
                        onChanged={this.handleChanged}
                    />
                )}
                {selected_idx == 2 && (
                    <TenantSettingsTab
                        initial_info={initial_info}
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        reload={reload}
                        changed={changed}
                        showToast={showToast}
                        onCreate={this.handleCreate}
                        onSave={this.handleSave}
                        onChanged={this.handleChanged}
                        onRemove={this.handleRemove}
                        onRevert={this.handleRevert}
                    />
                )}
                {selected_idx == 3 && (
                    <TenantNavigationTab
                        initial_info={initial_info}
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        reload={reload}
                        changed={changed}
                        showToast={showToast}
                        onChanged={this.handleChanged}
                        onSave={this.handleSave}
                        onRevert={this.handleRevert}
                    />
                )}
                {selected_idx == 4 && (
                    <TenantPresentationTab
                        initial_info={initial_info}
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        reload={reload}
                        showToast={showToast}
                        onChanged={this.handleChanged}
                    />
                )}
                <Drip7OriginalModal>
                    {show_create && (
                        <TenantModal 
                            showToast={showToast}
                            tenant_uid={tenant_uid}
                            super_admin={usr_info.role.toLowerCase() == 'super admin'}
                            onClose={this.handleCloseModal.bind(this)} />
                    )}
                </Drip7OriginalModal>
            </div>
        );
    }
}

export const Tenants = withStore(Klass);
