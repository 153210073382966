import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import { findContent } from "../../helpers/content";
import Language from "../../helpers/language";

import { Access, Languages } from "../../helpers/consts";
import { ModalTabsWidget } from "../../components/modal_tabs_widget";
import { DripQuestions } from "../../components/user/drip_questions";
import {
    QuestionBuilderWidget
} from "../../components/admin/question_builder_widget";
import {
    QuestionFollowUpWidget
} from "../../components/admin/question_follow_up_widget";
import {Drip7Dropdown} from "../../components/drip7_dropdown";


export const QuestionModal = (props) => {
    const {tenant_uid, qrcode_allowed, showToast, onClose} = props

    const [state, setState] = useState({
        language: Languages.English,
        question: props.question,
        access: { read: true, write: !('uid' in props.question), exec: true },
        selected_idx: 0,
        contents: [],
        tag_input: '',
        raw_files: {},
        category_questions: [],
        categories: [],
        curriculums: [],
        follow_up_uids: [],
    })
    const { language, question, access, selected_idx, follow_up_uids, curriculums, contents, category_questions, raw_files, categories, tag_input } = state

    useEffect(() => {
        //Pull the details for the question
        if ("uid" in question) {
            Util.fetch_js( "/question/detail/", { question_uid: question.uid },
                js => {
                    let { access, question, contents, tags } = js;

                    const [_0, _1, update_contents] = findContent( language, contents );

                    //Now we can load the content with the correct access
                    handleLoadCategories( !access.write )

                    setState(prev => ({ ...prev,
                        access,
                        question,
                        tag_input: tags.map( x => x.name ).join(','),
                        contents: update_contents,
                        follow_up_uids: question.follow_up_uids,
                    }))
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }
        else {
            handleLoadCategories( !access.write )
        }
    }, [])

    const handleLoadCategories = ( read_only ) => {
        const access = (read_only)? Access.ALL: Access.EXEC

        //Get all the categories
        Util.fetch_js( "/category/list/", { tenant_uid, access },
            js => {
                //Store the categories!
                setState(prev => ({ ...prev,
                    categories: js.categories
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        )

        //Get all the categories
        Util.fetch_js( "/curriculum/list/", { tenant_uid, access },
            js => {
                //Store the categories!
                setState(prev => ({ ...prev,
                    curriculums: js.curriculums
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        )

        //Get the question info
        const payload = {
            tenant_uid,
            exclude_types: ["phish"],
            access,
        }
        Util.fetch_js( "/question/list/", payload,
            js => {
                setState(prev => ({ ...prev,
                    category_questions: js.categories
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        )
    }

    const handleChange = (e) => {
        //If we don't have write access, block this action
        if ( !access.write ) {
            return
        }

        //Handle changes
        if ( e.target.id == 'language' ) {
            const language = e.target.value;

            //Ensure contents is valid if we changing language
            const [_0, _1, update_contents] = findContent( language, contents );

            setState(prev => ({ ...prev,
                language,
                contents: update_contents
            }))
        }
        else if ( e.target.id == 'tag_input' ) {
            question.tags = e.target.value.split(",").map( tag => tag.trim() )

            setState(prev => ({ ...prev,
                question,
                tag_input: e.target.value,
            }))
        }
        else {
            question[e.target.id] = e.target.value;
            setState(prev => ({ ...prev,
                question
            }))
        }
    }

    const handleFileChange = (prepend, raw_file) => {
        //If we don't have write access, block this action
        if ( !access.write || raw_file === null || raw_file === undefined ) {
            return
        }

        //Store the file
        const key = `${prepend}_${language}`
        console.log(key)
        raw_files[key] = new File([raw_file], key, {type: raw_file.type})
        console.log(raw_files)
        setState(prev => ({ ...prev,
            raw_files
        }))
    }

    const handleContentChange = (type, content, apply_to_all) => {
        //If we don't have write access, block this action
        if ( !access.write ) {
            return
        }

        for (let i = 0; i < contents.length; i++) {
            if (contents[i].language == language) {
                contents[i][type] = content
            }

            if (apply_to_all) {
                contents[i][type] = { ...contents[i][type], ...apply_to_all }
            }
        }

        setState(prev => ({...prev,
            contents
        }))
    }

    const handleFollowUpUids = (follow_up_uids) => {
        setState(prev => ({...prev,
            follow_up_uids,
        }))
    }

    //Save before exiting
    const handleSave = () => {
        if ( !access.write ) {
            return
        }

        if ( !question.category_uid ) {
            showToast("Please select a category", "failure")
            return
        }

        if ( !question.name || question.name.length < 3 ) {
            showToast(Language.getSentenceCase("Name must have at least 3 characters"), "failure")
            return
        }

        let payload = {
            category_uid: question.category_uid,
            name: question.name,
            content_type: question.content_type,
            answer_type: question.answer_type,
            contents: contents,
            tags: tag_input.split(",").map( tag => tag.trim() ),
            follow_up_uids,
        };

        //Add raw files
        Object.entries(raw_files).map( ([key, value]) => {
            payload[`file_${key}`] = value
        })

        if (question.uid) {
            payload["question_uid"] = question.uid
        }

        //Setup my endpoint and message for success
        let ep = question.uid? "/question/modify/": "/question/create/"
        let msg = question.uid? "updated question": 'created question'

        //Make the call!
        Util.fetch_js( ep, payload,
            js => {
                showToast(Language.getSentenceCase(msg), 'successful');
                onClose(true);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        )
    }

    const clean_categories = categories.filter( x => (x.phish_count <= 0) )
    const category = clean_categories.find(x => x.uid == question.category_uid ) || {name: "Categories"}

    const [content, placeholder, _] = findContent( language, contents );

    const tabs = [
        { name: Language.getTitleCase('content'), icon: <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z' fill='currentColor'></path> </svg> },
        { name: Language.getTitleCase('follow up'), icon: <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M6.5625 3.7168V5.12305L7.41211 6.00195C7.20703 4.97656 7.5293 3.95117 8.26172 3.21875C8.84766 2.60352 9.63867 2.31055 10.459 2.31055H10.4883L8.81836 3.98047L9.25781 6.61719L11.8945 7.05664L13.5938 5.38672C13.5938 6.20703 13.2715 7.02734 12.6562 7.61328C12.3926 7.90625 12.0703 8.11133 11.748 8.25781C11.7773 8.28711 11.8359 8.3457 11.8945 8.375L12.8027 9.2832C13.0957 9.10742 13.3887 8.87305 13.6523 8.60938C14.7656 7.49609 15.2344 5.85547 14.8535 4.33203C14.7656 3.95117 14.502 3.6875 14.1504 3.57031C13.7695 3.48242 13.3887 3.59961 13.1543 3.83398L11.4258 5.5625L10.459 5.41602L10.3125 4.44922L12.041 2.75C12.3047 2.48633 12.3926 2.10547 12.3047 1.75391C12.1875 1.40234 11.9238 1.10938 11.543 1.02148C9.99023 0.640625 8.37891 1.08008 7.26562 2.22266C6.97266 2.51562 6.70898 2.83789 6.50391 3.21875H6.5625V3.7168ZM3.10547 14.1758C2.72461 14.5566 2.05078 14.5566 1.66992 14.1758C1.49414 14 1.37695 13.7363 1.37695 13.4727C1.37695 13.209 1.49414 12.9746 1.66992 12.7695L5.625 8.84375L4.62891 7.84766L0.703125 11.7734C0.234375 12.2422 0 12.8574 0 13.4727C0 14.1172 0.234375 14.7324 0.703125 15.1719C1.14258 15.6406 1.75781 15.875 2.40234 15.875C3.01758 15.875 3.63281 15.6406 4.10156 15.1719L7.03125 12.2422C6.76758 11.7734 6.5918 11.2461 6.5918 10.6895L3.10547 14.1758ZM14.6777 12.4766L11.25 9.04883C10.5469 8.375 9.55078 8.22852 8.73047 8.63867L5.625 5.5332V3.6875L1.875 0.875L0 2.75L2.8125 6.5H4.62891L7.73438 9.63477C7.35352 10.4551 7.4707 11.4512 8.14453 12.125L11.5723 15.582C12.0117 15.9922 12.6855 15.9922 13.125 15.582L14.6777 14.0293C15.0879 13.5898 15.0879 12.916 14.6777 12.4766Z' fill='currentColor'></path> </svg> },
        { name: Language.getTitleCase('preview'), icon: <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' /> </svg> },
    ]

    return (
        <div className='basic-modal basic-modal--create-curriculum' tabIndex={-1}>
            <p className='hidden-heading'>
                {Language.getTitleCase('dialog content starts')}
            </p>
            <div className='basic-modal__head'>
                <h4>
                    {Language.getTitleCase('question')}
                    {!access.write ? ` ( ${Language.getTitleCase("read only")} )`: ''}
                </h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className='basic-modal__field-container'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='name'>
                        {Language.getTitleCase('name')}
                    </label>
                    <input
                        type='text'
                        className='mt-1 border-1 border-gray-300 rounded'
                        id='name'
                        readOnly={!access.write}
                        placeholder={Language.getSentenceCase('Computer and cyber crimes')}
                        name='name'
                        value={question.name}
                        onChange={handleChange}
                    />
                </div>

                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'>
                        {Language.getTitleCase('category')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='category_uid'
                            value={category}
                            entries={clean_categories}
                            onChange={x => handleChange({target: {id: 'category_uid', value: x.target.value.uid}})}
                            display={x => x.name}
                            overflow='max-h-44'
                            className='w-60'
                            />
                    </div>
                </div>

                {/*}
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'>
                        {Language.getTitleCase('tags')}
                    </label>
                    <input
                        type='text'
                        className='mt-1 border-1 border-gray-300 rounded'
                        id='tag_input'
                        readOnly={!access.write}
                        placeholder={'tag1,tag2'}
                        value={tag_input}
                        onChange={handleChange}
                    />
                </div>
                {*/}

                <div className='basic-modal__language-selection mt-3'>
                    <div className='basic-modal__language-container'>
                        <Drip7Dropdown
                            id='language'
                            value={language}
                            entries={Object.values(Languages)}
                            onChange={x => handleChange({target: {id: 'language', value: x.target.value}})}
                            display={x => Language.getSentenceCase(x)}
                            overflow='max-h-44'
                        />
                    </div>
                </div>
            </div>

            <ModalTabsWidget
                tabs={tabs}
                selected_idx={selected_idx}
                onChange={selected_idx => setState( prev => ({ ...prev, selected_idx }))}
                />

            {selected_idx == 0 &&
                <QuestionBuilderWidget
                    access={access}
                    language={language}
                    question={question}
                    qrcode_allowed={qrcode_allowed}
                    contents={contents}
                    categories={categories}
                    onChange={handleChange}
                    onContentChange={handleContentChange}
                    onFileChange={handleFileChange}
                    showToast={showToast}
                    />
            }

            {selected_idx == 1 &&
                <QuestionFollowUpWidget
                    access={access}
                    language={language}
                    question={question}
                    contents={contents}
                    follow_up_uids={follow_up_uids}
                    curriculums={curriculums}
                    categories={category_questions}
                    onChange={handleFollowUpUids}
                />
            }

            {selected_idx == 2 &&
                <DripQuestions
                    dryRun={true}
                    user_questions={[{ question, question_content: content }]}
                    showToast={showToast}
                />
            }

            <div className='basic-modal__button-container--save-bottom'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    disabled={!access.write}
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
        </div>
    );
}
