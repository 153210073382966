import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { QuestionsTab } from "./questions_tab";
import { CategoriesTab } from "./categories_tab";
// import { PhishTemplatesTab } from "./phish_templates_tab";
import {Access} from "../../helpers/consts";

export const Content = (props) => {
    const { tenant_uid, tenants, showToast } = props;

    const [state, setState] = useState({
        tenant_uid: "",
        show_answers: false,
        question_categories: [],
        categories: [],
        selected_idx: 0
    })
    const { show_answers, question_categories, categories, selected_idx } = state

    const updateContent = (show_answers) => {
        //Get a list of all members for this tenant
        const payload = {
            tenant_uid,
            show_answers,
            access: Access.EXEC
        };
        Util.fetch_js( "/question/list/", payload,
            js => {
                //Seems wierd, but call another download that is much slower with more detailed information
                if ( !show_answers ) {
                    updateContent( true )
                }

                //Store the categories!
                setState(prev => ({...prev,
                    show_answers: true,
                    question_categories: js.categories
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        if ( show_answers ) {
            //Get all the categories
            Util.fetch_js( "/category/list/", { tenant_uid, access: Access.EXEC },
                js => {
                    //Store the categories!
                    setState(prev => ({...prev,
                        categories: js.categories
                    }))
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }

        //Setup the tenant_uid to block multiloads
        setState(prev => ({
            ...prev,
            tenant_uid,
        }))
    }

    useEffect(() => {
        props.onPathChange("content")
    }, [])

    useEffect(() => {
        if ( props.tenant_uid != state.tenant_uid ) {
            updateContent( show_answers )
        }
    }, [props])

    return (
        <div className='questions main-modal'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>
                    <li className={ "central__nav-item central__nav-item--small " + (selected_idx == 0 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 0 }))}>
                        <svg
                            width='15'
                            height='12'
                            viewBox='0 0 15 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase('questions')}
                    </li>
                    {/* <li className={ "central__nav-item central__nav-item--wide " + (selected_idx == 1 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 1 }))}>
                        <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' />
                        </svg>
                        {Language.getTitleCase('phish templates')}
                    </li> */}
                    <li className={ "central__nav-item central__nav-item--wide " + (selected_idx == 2 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 2 }))}>
                        <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M6.5625 3.7168V5.12305L7.41211 6.00195C7.20703 4.97656 7.5293 3.95117 8.26172 3.21875C8.84766 2.60352 9.63867 2.31055 10.459 2.31055H10.4883L8.81836 3.98047L9.25781 6.61719L11.8945 7.05664L13.5938 5.38672C13.5938 6.20703 13.2715 7.02734 12.6562 7.61328C12.3926 7.90625 12.0703 8.11133 11.748 8.25781C11.7773 8.28711 11.8359 8.3457 11.8945 8.375L12.8027 9.2832C13.0957 9.10742 13.3887 8.87305 13.6523 8.60938C14.7656 7.49609 15.2344 5.85547 14.8535 4.33203C14.7656 3.95117 14.502 3.6875 14.1504 3.57031C13.7695 3.48242 13.3887 3.59961 13.1543 3.83398L11.4258 5.5625L10.459 5.41602L10.3125 4.44922L12.041 2.75C12.3047 2.48633 12.3926 2.10547 12.3047 1.75391C12.1875 1.40234 11.9238 1.10938 11.543 1.02148C9.99023 0.640625 8.37891 1.08008 7.26562 2.22266C6.97266 2.51562 6.70898 2.83789 6.50391 3.21875H6.5625V3.7168ZM3.10547 14.1758C2.72461 14.5566 2.05078 14.5566 1.66992 14.1758C1.49414 14 1.37695 13.7363 1.37695 13.4727C1.37695 13.209 1.49414 12.9746 1.66992 12.7695L5.625 8.84375L4.62891 7.84766L0.703125 11.7734C0.234375 12.2422 0 12.8574 0 13.4727C0 14.1172 0.234375 14.7324 0.703125 15.1719C1.14258 15.6406 1.75781 15.875 2.40234 15.875C3.01758 15.875 3.63281 15.6406 4.10156 15.1719L7.03125 12.2422C6.76758 11.7734 6.5918 11.2461 6.5918 10.6895L3.10547 14.1758ZM14.6777 12.4766L11.25 9.04883C10.5469 8.375 9.55078 8.22852 8.73047 8.63867L5.625 5.5332V3.6875L1.875 0.875L0 2.75L2.8125 6.5H4.62891L7.73438 9.63477C7.35352 10.4551 7.4707 11.4512 8.14453 12.125L11.5723 15.582C12.0117 15.9922 12.6855 15.9922 13.125 15.582L14.6777 14.0293C15.0879 13.5898 15.0879 12.916 14.6777 12.4766Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase('categories')}
                    </li>
                </ul>
            </nav>

            {selected_idx == 0 && (
                <QuestionsTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    categories={categories}
                    question_categories={question_categories}
                    showToast={showToast}
                    onRefreshCategories={() => updateContent( show_answers )}
                />
            )}
{/* 
            {selected_idx == 1 && (
                <PhishTemplatesTab
                    tenant_uid={tenant_uid}
                    categories={categories}
                    question_categories={question_categories}
                    showToast={showToast}
                    onRefreshCategories={() => updateContent( show_answers )}
                />
            )} */}

            {selected_idx == 2 && (
                <CategoriesTab
                    tenant_uid={tenant_uid}
                    categories={categories}
                    showToast={showToast}
                    onRefreshCategories={() => updateContent(show_answers)}
                />
            )}
        </div>
    );
}
