import React from "react";
import Language from "../../helpers/language";

export const ContentPhishWidget = props => {
    const { access, question, content, showToast, onChange } = props;
    const question_uid = question.uid || "";
    const subject = content.subject || "";
    const body = content.body || "";

    const handleChange = e => {
        if ( !access.write ) {
            return
        }

        onChange('content', { ...content, [e.target.id]: e.target.value })
    };

    return (
        <div className="width-100 m-v-sm">
            <div className='basic-modal__field width-100 m-b-sm'>
                <label className='basic-modal__label'>{Language.getTitleCase('subject')}</label>
                <input
                    className='basic-modal__input width-100'
                    placeholder={Language.getSentenceCase('Phish subject')}
                    id='subject'
                    value={subject}
                    readOnly={!access.write}
                    onChange={handleChange}
                />
            </div>
            <div className='basic-modal__field width-100'>
                <label className='basic-modal__label'>
                    {Language.getSentenceCase('body')} ({" "}
                    <a
                        href='/static/replacement_codes.pdf'
                        target='_blank'
                        rel='noopener'>
                        {Language.getTitleCase('View Codes')}
                    </a>{" "}
                    )
                </label>
                <textarea
                    className='basic-modal__input basic-modal__input--add-question width-100'
                    style={{ height: 400 }}
                    placeholder={Language.getSentenceCase('Phish content here')}
                    id='body'
                    readOnly={!access.write}
                    onChange={handleChange}
                    value={body}
                />
            </div>
        </div>
    );
};
