import React from "react";
import Language from "../../helpers/language";
import Util from "../../helpers/util";
import TrashImg from "../../assets/images/admin/trash.svg"
import {Drip7Dropdown} from "../drip7_dropdown";

export const QueueCampaignRowWidget = props => {
    const { campaign, onChange, onRemove } = props;

    let { month, week_of_month, day_of_week } = campaign;

    const handleInc = e => {
        if (week_of_month >= 4) {
            return;
        }
        onChange("week_of_month", week_of_month + 1);
    };

    const handleDec = e => {
        if (week_of_month < 0) {
            return;
        }

        onChange("week_of_month", week_of_month - 1);
    };

    const handleChange = e => {
        if (e.target.id == "week_of_month") {
            const dow = Math.max( Math.min( parseInt(e.target.value), 4), 0)
            onChange(e.target.id, dow);
        }
        else {
            onChange(e.target.id, parseInt(e.target.value));
        }
    };

    const monthName = m => {
        return (m === 0 || m === null) ? Language.getTitleCase('All Months') : Util.toLongMonth(m - 1);
    };

    return (
        <tr>
            <td>
                <div className='dropdown dropdown--attached dropdown--curriculum-config'>
                    <Drip7Dropdown
                        id='month'
                        name='month'
                        onChange={handleChange}
                        value={month}
                        entries={[0,1,2,3,4,5,6,7,8,9,10,11,12]}
                        display={monthName}
                        overflow='max-h-52'
                        />
                </div>
            </td>
            <td className="pt-1 flex flex-row justify-content-center items-center">
                {week_of_month == 0 &&
                    <button type="button"
                            className="rounded bg-drip7 h-10 w-20 px-2 py-1 mt-0.5 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 inline-flex justify-content-between items-center"
                            onClick={handleInc}>
                        {Language.getTitleCase('All')}
                        <div className='number-button__container ml-8'>
                            <svg
                                className='number-button number-button--up'
                                width='10'
                                height='6'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                    fill='#FFF'></path>
                            </svg>
                            <svg
                                className='number-button number-button--down'
                                id='numBtnDown'
                                width='10'
                                height='6'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                    fill='#FFF'></path>
                            </svg>
                        </div>
                    </button>
                }
                {week_of_month > 0 &&
                    <div className='basic-modal__input-wrapper w-20 h-11'>
                        <input
                            type='number'
                            className='basic-modal__input basic-modal__input--number'
                            id='week_of_month'
                            placeholder='00'
                            value={week_of_month}
                            style={{width: "48px"}}
                            onChange={handleChange}
                        />
                        <div className='number-button__container'>
                            <svg
                                className='number-button number-button--up'
                                width='10'
                                height='6'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                onClick={handleInc}>
                                <path
                                    d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                    fill='#1F4760'></path>
                            </svg>
                            <svg
                                className='number-button number-button--down'
                                id='numBtnDown'
                                width='10'
                            height='6'
                            viewBox='0 0 10 6'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            onClick={handleDec}>
                            <path
                                d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                fill='#1F4760'></path>
                        </svg>
                    </div>
                </div>
                }
            </td>
            <td>
                <div className='dropdown dropdown--attached dropdown--curriculum-config'>
                    <Drip7Dropdown
                        id='day_of_week'
                        name='day_of_week'
                        value={day_of_week}
                        display={Util.getDayName}
                        overflow='max-h-52'
                        entries={[0, 1, 2, 3, 4, 5, 6]}
                        onChange={handleChange}
                        />
                </div>
            </td>
            <td>
                <img
                    src={TrashImg}
                    alt='trash'
                    style={{ cursor: "pointer", width: '24px', height: '24px' }}
                    onClick={onRemove}
                />
            </td>
        </tr>
    );
};
