import React from "react";

import { useStore } from "../../store";
import Util from "../../helpers/util";

import { Access, BadgeCriteria, Languages } from "../../helpers/consts";
import {
    BadgeCollectionWidget
} from "../../components/admin/badge_collection_widget";
import Language from "../../helpers/language";
import {Drip7Dropdown} from "../../components/drip7_dropdown";

const withStore = (BaseComponent) => (props) => {
    const { usr_info } = useStore((state) => ({ usr_info: state.usr_info }));
    return (
        <BaseComponent {...props} usr_info={usr_info} fileRef={React.useRef()} />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        let { badge } = props;
        let access = {};
        if (badge == true || badge == null || badge == undefined) {
            badge = { name: "", image: "" };
            access = { read: true, write: true, assign: false };
        }

        this.state = {
            filename: "",
            raw_file: null,
            language: Languages.English,
            badge,
            access,
            categories: [],
            contents: [],
            collections: [],
            current_parent: !badge.parent ? {name: Language.getTitleCase('None'), value: ''} : badge.parent,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleCheckedState = this.handleCheckedState.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleLanguageDropdown = this.handleLanguageDropdown.bind(this);
        this.handleParentDropdown = this.handleParentDropdown.bind(this);

        this.handleAddCollection = this.handleAddCollection.bind(this);
        this.handleRemoveCollection = this.handleRemoveCollection.bind(this);
        this.handleCollectionChange = this.handleCollectionChange.bind(this);
    }

    componentDidMount() {
        const { tenant_uid, showToast } = this.props;
        const { badge, language } = this.state;
        const payload = {
            tenant_uid,
            access: Access.EXEC,
        };

        //Get the category info
        Util.fetch_js(
            "/category/list/",
            payload,
            (js) => {
                this.setState({ categories: js.categories });
            },
            (err, code) => {
                showToast(err, "failure");
            }
        );

        //Pull the details for the badge
        if ("uid" in badge) {
            Util.fetch_js(
                "/badge/detail/",
                { badge_uid: badge.uid },
                (js) => {
                    let { badge, access, contents, schedules, collections } = js;

                    const [_, update_contents] = this.findContent(language, contents);

                    this.setState({
                        badge,
                        access,
                        contents: update_contents,
                        schedules,
                        collections,
                        current_parent: badge.parent || {name: Language.getTitleCase('None'), value: ''},
                    });
                },
                (reason, code) => {
                    showToast(reason, "failure");
                }
            );
        }
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        let { badge } = this.state;
        badge[name] = value;

        this.setState({ badge });
    }

    handleToggle(event) {
        const { access } = this.state;

        if (!access.write) {
            return;
        }

        const name = event.target.name;
        const value = event.target.value;

        let { badge } = this.state;
        badge[name] = !badge[name];

        this.setState({ badge });
    }

    handleCheckedState(event) {
        const name = event.target.name;
        const checked = event.target.checked;

        this.setState({ [name]: checked });
    }

    handleContentChange(event) {
        const { contents, language } = this.state;

        const [content, update_contents] = this.findContent(language, contents);

        const name = event.target.name;
        const value = event.target.value;
        content[name] = value;

        for (let i = 0; i < update_contents; i++) {
            if (update_contents[i].language == language) {
                update_contents[i] = content;
            }
        }

        this.setState({ contents: update_contents });
    }

    handleLanguageDropdown(e) {
        let { contents } = this.state;
        const language = e.target.value;

        //Ensure contents is valid if we changing language
        const [_, update_contents] = this.findContent(language, contents);

        this.setState({ language, contents: update_contents });
    }

    handleParentDropdown(e) {
        const id = e.target.id;
        const value = e.target.value;

        let { badge } = this.state;
        badge[id] = value.uid;

        this.setState({ badge, current_parent: value })
    }

    handleFileChange(e) {
        const { showToast } = this.props;
        let { badge, access } = this.state;
        const raw_file = e.target.files[0];

        if (!access.write) {
            return;
        }

        //Create object url for the image
        try {
            badge.image = URL.createObjectURL(raw_file);
        }
        catch (e) {
            console.log(e)
            showToast("Error loading image", "error");
            return
        }

        //Set the state
        this.setState({
            badge,
            filename: raw_file.name,
            raw_file,
        });
    }

    handleAddCollection() {
        let { access, collections } = this.state;

        if (!access.write) {
            return;
        }

        collections.push({
            category_uid: "",
            criteria: BadgeCriteria.CORRECT_ANSWERS,
            params: { count: 0 },
        });
        this.setState({ collections });
    }

    handleRemoveCollection(idx) {
        let { access, collections } = this.state;

        if (idx < 0 || idx >= collections.length || !access.write) {
            return;
        }

        collections.splice(idx, 1);
        this.setState({ collections });
    }

    handleCollectionChange(idx, key, value) {
        let { access, collections } = this.state;

        if (idx < 0 || idx >= collections.length || !access.write) {
            return;
        }

        //Questions?
        if (key == "count") {
            collections[idx][key] = parseInt(value);
        } else {
            collections[idx][key] = value;
        }

        this.setState({ collections });
    }

    findContent(language, contents) {
        let update_contents = contents;

        let content = contents.find((x) => {
            return x.language.toLowerCase() == language.toLowerCase();
        });
        if (content == null) {
            //Okay so we are going to create, can we copy from english?
            content = { language, message: "", congratulations: "" };

            //Store the new content and return
            update_contents.push(content);
        }

        return [content, update_contents];
    }

    render() {
        const {
            tenant_uid,
            tenants,
            fileRef,
            showToast,
            onClose,
            onAssign,
            badges,
        } = this.props;
        const {
            access,
            categories,
            badge,
            contents,
            collections,
            language,
            filename,
            raw_file,
            current_parent,
        } = this.state;

        const [content, safe_contents] = this.findContent(language, contents);

        //Funky pass through to open the file browse
        const handleFileClick = () => {
            if (!access.write) {
                return;
            }
            fileRef.current.click();
        };

        //Save before exiting
        const handleSave = () => {
            if (!("uid" in badge) && filename == "") {
                showToast(
                    Language.getSentenceCase("Please select a badge image"),
                    "error"
                );
                return;
            }

            let payload = {
                ...badge,
                badge_uid: badge.uid,
                tenant_uid,
                contents: safe_contents,
                collections,
            };
            if (filename != "") {
                payload.image = raw_file;
            }

            //Save it!
            const url = badge.uid ? "/badge/modify/" : "/badge/create/";
            Util.fetch_js(
                url,
                payload,
                (js) => {
                    showToast(Language.getSentenceCase("saved badge"), "successul");
                    onClose(true);
                },
                (err, code) => {
                    showToast(err, "failure");
                }
            );
        };

        console.log('badge', current_parent)

        return (
            <div className="basic-modal basic-modal--add-badge" tabIndex={-1}>
                <p className="hidden-heading">
                    {Language.getTitleCase("dialog content starts")}
                </p>
                <div className="basic-modal__head">
                    <h4>
                        {badge.uid ? "Modify " : "Create "} badge
                        {!access.write ? ` (${Language.getTitleCase("read only")})` : ""}
                    </h4>
                    <svg
                        className="basic-modal__close-button"
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={onClose}
                    >
                        <path
                            d="M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
                <div className="fields">
                    <div className="basic-modal__field">
                        <label className="basic-modal__label" htmlFor="newQuestionCategory">
                            {Language.getTitleCase("name")}
                        </label>
                        <input
                            type="text"
                            className="mt-1 border-1 border-gray-300 rounded"
                            id="newQuestionCategory"
                            placeholder={Language.getSentenceCase(
                                "Computer and cyber crimes"
                            )}
                            name="name"
                            readOnly={!access.write}
                            value={badge.name}
                            onChange={this.handleChange}
                        />

                        <div className="basic-modal__field column m-t-sm">
                            <div className="basic-modal__field">
                                <label className="basic-modal__label" htmlFor="newBadgeImg">
                                    {Language.getTitleCase("image")}
                                </label>
                                <div className="basic-modal__add-image-container">
                                    {badge.image ? (
                                        <img src={badge.image} alt='badge' onClick={handleFileClick} />
                                    ) : (
                                        <div
                                            className="flex row-wrap justify-center"
                                            onClick={handleFileClick}
                                        >
                                            <svg
                                                className="basic-modal__add-image-icon"
                                                width="45"
                                                height="34"
                                                viewBox="0 0 45 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.2"
                                                    d="M40.7812 34C43.0664 34 45 32.1543 45 29.7812V4.46875C45 2.18359 43.0664 0.25 40.7812 0.25H4.21875C1.8457 0.25 0 2.18359 0 4.46875V29.7812C0 32.1543 1.8457 34 4.21875 34H40.7812ZM9.84375 5.17188C12.4805 5.17188 14.7656 7.45703 14.7656 10.0938C14.7656 12.8184 12.4805 15.0156 9.84375 15.0156C7.11914 15.0156 4.92188 12.8184 4.92188 10.0938C4.92188 7.45703 7.11914 5.17188 9.84375 5.17188ZM5.625 28.375V24.1562L11.8652 17.916C12.3047 17.4766 12.9199 17.4766 13.3594 17.916L18.2812 22.75L30.1465 10.8848C30.5859 10.4453 31.2012 10.4453 31.6406 10.8848L39.375 18.5312V28.375H5.625Z"
                                                    fill="#607D8B"
                                                />
                                            </svg>

                                            <p className="basic-modal__upload-label">
                                                <span>
                                                    {Language.getSentenceCase("No image file selected")}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="basic-modal__field basic-modal__wrapper basic-modal__wrapper--add-badge">
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={(node) => (fileRef.current = node)}
                                    onChange={this.handleFileChange}
                                    style={{ display: "none" }}
                                />
                                <input
                                    className="mt-1 border-1 border-gray-300 rounded"
                                    type="text"
                                    readOnly
                                    name="user-file"
                                    placeholder={Language.getSentenceCase("No File Selected")}
                                    value={filename}
                                />
                                <button
                                    className="basic-modal__button basic-modal__button--solid"
                                    style={{backgroundColor: 'var(--drip7-primary-color)'}}
                                    type="button"
                                    name="choose-file"
                                    onClick={handleFileClick}
                                >
                                    {Language.getTitleCase("Choose File")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="basic-modal__field">
                        <label className="basic-modal__label" htmlFor="newQuestionCategory">
                            {Language.getTitleCase("points")}
                        </label>
                        <input
                            type="number"
                            className="mt-1 border-1 border-gray-300 rounded"
                            id="points"
                            placeholder="0"
                            name="points"
                            readOnly={!access.write}
                            value={badge.points}
                            onChange={this.handleChange}
                        />

                        <div className="col-gap-sm m-t-sm">
                            <label className="basic-modal__label">Settings</label>
                            <div className="customization-checkbox customization-checkbox__badge">
                                <input
                                    type="checkbox"
                                    aria-label="checkbox"
                                    name="obscure"
                                    id="obscure"
                                    readOnly={!access.write}
                                    checked={badge.obscure}
                                    onChange={this.handleToggle}
                                />
                                <span className="checkmark"></span>
                                <label htmlFor="obscure">
                                    {Language.getTitleCase("obscure")}
                                </label>
                                <span className="tooltip__text">
                                    {Language.getSentenceCase(
                                        "Badge image not visible until earned"
                                    )}
                                </span>
                            </div>

                            <div className="customization-checkbox customization-checkbox__badge">
                                <input
                                    type="checkbox"
                                    aria-label="checkbox"
                                    name="hidden"
                                    id="hidden"
                                    readOnly={!access.write}
                                    checked={badge.hidden}
                                    onChange={this.handleToggle}
                                />
                                <span className="checkmark"></span>
                                <label htmlFor="hidden">
                                    {Language.getTitleCase("hidden")}
                                </label>
                                <span className="tooltip__text">
                                    {Language.getSentenceCase("Badge not visible until earned")}
                                </span>
                            </div>

                            <div className="customization-checkbox customization-checkbox__badge">
                                <input
                                    type="checkbox"
                                    aria-label="checkbox"
                                    name="cascade"
                                    id="cascade"
                                    readOnly={!access.write}
                                    checked={badge.cascade}
                                    onClick={this.handleToggle}
                                />
                                <span className="checkmark"></span>
                                <label htmlFor="users-can-edit">
                                    {Language.getTitleCase("sub tenant access")}
                                </label>
                                {/* <span className="tooltip__text">{Language.getSentenceCase('No text yet')}</span>    */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fields m-t-sm">
                    <div className="basic-modal__field basic-modal__field--add-question">
                        <label className="basic-modal__label">
                            {Language.getTitleCase("Parent Badge")}
                        </label>
                        <div className="dropdown dropdown--attached dropdown--add-question">
                            <Drip7Dropdown
                                id="parent_uid"
                                value={current_parent}
                                display={x => x.name}
                                entries={badges}
                                empty={{name: Language.getTitleCase("None"), uid: ''}}
                                overflow='max-h-40'
                                onChange={this.handleParentDropdown}
                                />
                        </div>
                    </div>
                    <div className="basic-modal__field basic-modal__field--add-question">
                        <label className="basic-modal__label">
                            {Language.getTitleCase("language")}
                        </label>
                        <div className="dropdown dropdown--attached dropdown--add-question">
                            <Drip7Dropdown
                                id="language"
                                value={language}
                                entries={Language.getSupportedLanguages()}
                                display={x => Language.getTitleCase(x)}
                                overflow='max-h-40'
                                onChange={this.handleLanguageDropdown}
                                />
                        </div>
                    </div>
                </div>
                <div className="fields m-t-sm">
                    <div className="basic-modal__field basic-modal__field--add-badge">
                        <label className="basic-modal__label" htmlFor="message">
                            {Language.getTitleCase("message")}
                        </label>
                        <textarea
                            className="basic-modal__input basic-modal__input--textarea"
                            placeholder={Language.getSentenceCase("Badge's message")}
                            name="message"
                            readOnly={!access.write}
                            value={content.message}
                            onChange={this.handleContentChange}
                        ></textarea>
                    </div>

                    <div className="basic-modal__field basic-modal__field--add-badge">
                        <label className="basic-modal__label" htmlFor="congratulations">
                            {Language.getTitleCase("congratulations")}
                        </label>
                        <textarea
                            className="basic-modal__input basic-modal__input--textarea"
                            placeholder={Language.getSentenceCase("Badge's congratulations")}
                            name="congratulations"
                            readOnly={!access.write}
                            value={content.congratulations}
                            onChange={this.handleContentChange}
                        ></textarea>
                    </div>
                </div>

                <div className="fields m-t-sm">
                    <button
                        disabled={!access.write}
                        className="basic-modal__button basic-modal__button--solid basic-modal__button--add"
                        onClick={this.handleAddCollection}
                    >
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.0625 5.96875H8.84375V1.75C8.84375 1.25195 8.4043 0.8125 7.90625 0.8125H6.96875C6.44141 0.8125 6.03125 1.25195 6.03125 1.75V5.96875H1.8125C1.28516 5.96875 0.875 6.4082 0.875 6.90625V7.84375C0.875 8.37109 1.28516 8.78125 1.8125 8.78125H6.03125V13C6.03125 13.5273 6.44141 13.9375 6.96875 13.9375H7.90625C8.4043 13.9375 8.84375 13.5273 8.84375 13V8.78125H13.0625C13.5605 8.78125 14 8.37109 14 7.84375V6.90625C14 6.4082 13.5605 5.96875 13.0625 5.96875Z"
                                fill="currentColor"
                            />
                        </svg>
                        {Language.getTitleCase("add criteria")}
                    </button>
                </div>

                {Object.entries(collections).map(([key, collection]) => (
                    <BadgeCollectionWidget
                        key={"cirrculum_collection_" + key}
                        categories={categories}
                        idx={key}
                        access={access}
                        collection={collection}
                        onChange={this.handleCollectionChange}
                        onRemove={() => this.handleRemoveCollection(key)}
                    />
                ))}

                <div className="basic-modal__button-container-around m-t-sm">
                    <button
                        disabled={!("uid" in badge) && !access.exec}
                        className="basic-modal__button basic-modal__button--solid"
                        onClick={() => onAssign(badge)}
                    >
                        {Language.getTitleCase("assign")}
                    </button>

                    <button
                        className="basic-modal__button basic-modal__button--clear"
                        onClick={onClose}
                    >
                        {Language.getTitleCase("cancel")}
                    </button>
                    <button
                        disabled={!access.write}
                        className="basic-modal__button basic-modal__button--solid"
                        onClick={handleSave}
                    >
                        {Language.getTitleCase("save")}
                    </button>
                </div>
                <p className="hidden-heading">
                    {Language.getTitleCase("dialog content ends")}
                </p>
            </div>
        );
    }
}

export const BadgeModal = withStore(Klass);
