import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import {
    AuthenticationType, MemberRole, Notification
} from "../../helpers/consts";
import { ConfirmModal } from "../../modals/confirm_modal";
import EmailImg from "../../assets/images/shared/email.svg"
import { MembershipModal } from "./membership_modal";
import { Drip7OriginalModal } from "../drip7_original_modal";
import { Drip7Input } from "../../components/drip7_input";
import { Drip7Combobox } from "../../components/drip7_combobox";
import { Drip7Button } from "../../components/drip7_button";
import {DialogTitle} from "@headlessui/react";
import {
    EnvelopeIcon,
    NoSymbolIcon,
    StarIcon,
    TrophyIcon
} from "@heroicons/react/24/outline";
import {TrashIcon} from "@heroicons/react/20/solid";


export const UserModal = (props) => {
    const { tenant_uid, tenants, showToast, onAvatar, onShowPoints, onClose } = props

    const [state, setState] = useState({
        info: props.info,
        membership: (props.info && props.info.groups != "")? props.info.groups.split(","): [],
        groups: [],
        confirm: null,
        email_intro: true,
        show_groups_modal: false,
    })
    const { info, membership, groups, confirm, email_intro, show_groups_modal } = state

    const tenant = tenants.find( x => x.uid == tenant_uid) || {}

    useEffect(() => {
        //Get a list of all members for this tenant
        Util.fetch_js( "/tenant/details/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    groups: js.groups,
                }))
            },
            (err, code) => {
                showToast( err.reason, "successful")
            }
        );
    }, [])

    //On input changes, update my info object
    const handleChange = (event) => {
        const new_info = { ...info, [event.target.name]: event.target.value };

        setState(prev => ({ ...prev, info: new_info }))
    }

    //On input checks, update my info object
    const handleChecked = (event) => {
        const new_info = { ...info, [event.target.name]: event.target.checked };

        setState(prev => ({ ...prev, info: new_info }))
    }

    const handleGroupCheck = (group) => {
        const pos = membership.indexOf(group);
        if (pos != -1) {
            membership.splice(pos, 1)
        }
        else {
            membership.push(group)
        }

        setState(prev => ({...prev, membership }))
    }

    const toggleGroupModal = () => {
        setState(prev => ({ ...prev, show_groups_modal: !show_groups_modal }))
    }

    const handleDisableUser = () => {
        const disable = !info.disabled

        //Change disable state on the server
        const payload = {
            user_uids: [info.uid],
            disable,
        };
        Util.fetch_js( "/human/disable/", payload,
            js => {
                onClose()
                showToast(Language.getSentenceCase('updated'), 'successful')

                info.disabled = disabled
                setState(prev => ({ ...prev, info }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        setState(prev => ({ ...prev, confirm: null }))
    }

    const handleRemoveUser = (valid) => {
        const remove = (info.deleted_on == 0)
        const payload = {
            user_uids: [info.uid],
            remove,
        };
        Util.fetch_js( "/human/remove/", payload,
            js => {
                onClose()
                showToast(Language.getSentenceCase('updated'), 'successful')

                info.deleted_on = remove ? js.deleted_on : 0;
                setState(prev => ({...prev, info }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        setState(prev => ({ ...prev, confirm: null }))
    }

    const handleSaveChanges = () => {
        let payload = {
            name: info.name,
            nickname: info.nickname,
            email: info.email,
            role: info.role,
            phone: info.phone,
            presentation: info.presentation,
            groups: membership,
            skip_email_intro: !email_intro,
        };

        //Load up the data based on our action
        let endpoint = ""
        let succ = ""
        if ( ('uid' in info) && info.uid != "" ) {
            endpoint = "/human/modify/"
            payload['user_uid'] = info.uid
            succ = `${Language.getSentenceCase("updated")} ${info.name}`
        }
        else {
            endpoint = "/human/create/"
            payload['tenant_uid'] = tenant_uid
            succ = `${Language.getSentenceCase("created")} ${info.name}`
        }

            //Send out the udpate
        Util.fetch_js( endpoint, payload,
            js => {
                onClose()
                showToast(succ, 'successful')
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        );
    }

    const handleResetPassword = (action) => {
        //Send out the update
        Util.fetch_js( "/human/forgot/", { email: info.email },
            js => {
                onClose()
                showToast(Language.getSentenceCase('Reset password email sent to') + ": " + info.email)
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        );
    }

    const handleToggleEmailIntro = () => {
        setState(prev => ({ ...prev, email_intro: !email_intro }))
    };

    if (groups.length <= 0) {
        groups.push(Language.getSentenceCase('none'));
    }

    //Handle
    const confirmAction = (action) => {
        let yes = null;
        let msg = null;
        if (action == handleRemoveUser) {
            const remove = info.deleted_on == 0;
            yes = (remove ? Language.getTitleCase('remove') + " " : Language.getTitleCase('restore') + " ") + " " + Language.getTitleCase('member');
            msg = (remove ? Language.getTitleCase('remove') + " " : Language.getTitleCase('restore') + " ") + info.name + "?";
        }
        else if (action == handleDisableUser) {
            const disabled = !info.disabled;
            yes = disabled ?  Language.getTitleCase('disable') + " " : Language.getTitleCase('enable')
            msg = (disabled ? Language.getTitleCase('disable') + " " : Language.getTitleCase('enable') + " ") + info.name + "?";
        }
        else {
            throw Language.getSentenceCase('Program error, Unknown action') + ": " + action;
        }

        //Setting a confirm action, will load message box, and if accepted saves
        const new_confirm = { action, msg, yes, no: Language.getTitleCase('cancel') };

        setState(prev =>({...prev, confirm: new_confirm }))
    };

    const disableLabel = info.disabled ? Language.getTitleCase('enable user') : Language.getTitleCase('disable user');
    const removeLabel = info.deleted_on != 0 ? Language.getTitleCase('Restore User') : Language.getTitleCase('remove user');

    //Only show password reset button if this tenant is traditional login
    const existing_user = ('uid' in info)
    const show_pwd_reset = (info.tenant.auth_type == AuthenticationType.USER_PWD) && existing_user
    const title = existing_user? "edit member": 'create member'

    return (
        <div className="">
            <Drip7OriginalModal>
                {show_groups_modal && (
                    <MembershipModal
                        groups={groups}
                        membership={membership}
                        handleGroupCheck={handleGroupCheck}
                        onClose={toggleGroupModal}
                    />
                )}
            </Drip7OriginalModal>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3"
                             className="text-base font-semibold leading-6 text-gray-900">
                    {Language.getTitleCase(title)}
                </DialogTitle>
            </div>

            <form className="mt-4">
                <div className="space-y-12">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <img src={info.profile_url}
                                 alt='user avatar'
                                 style={{width: '160px', height: '160px'}}
                                 onClick={onAvatar}
                            />
                            <div className='flex flex-col mt-3 space-y-4'>
                                {!existing_user &&
                                    <div className="inline-flex items-center font-semibold text-sm text-drip7 cursor-pointer"
                                        onClick={handleToggleEmailIntro}>
                                        <input
                                            type='checkbox'
                                            aria-label='checkbox'
                                            readOnly
                                            checked={email_intro}
                                        />
                                        <EnvelopeIcon className="h-5 w-5 mr-2"/>
                                        {Language.getTitleCase('Email Introduction')}
                                    </div>
                                }
                                {existing_user &&
                                    <div className="inline-flex items-center font-semibold text-sm text-drip7 cursor-pointer"
                                        onClick={onShowPoints}>
                                        <StarIcon className="h-5 w-5 mr-2"/>
                                        {Language.getTitleCase('edit points')}
                                    </div>
                                }
                                {existing_user &&
                                    <div className="inline-flex items-center font-semibold text-sm text-red-600 cursor-pointer"
                                        onClick={() => confirmAction(handleRemoveUser)}>
                                        <TrashIcon className="h-5 w-5 mr-2"/>
                                        {removeLabel}
                                    </div>
                                }
                                {existing_user &&
                                    <div className="inline-flex items-center font-semibold text-sm text-red-600 cursor-pointer"
                                        onClick={() => confirmAction(handleDisableUser)}>
                                        <NoSymbolIcon className="h-5 w-5 mr-2"/>
                                        {disableLabel}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="mt-2 col-span-2">
                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                    <Drip7Input
                                        title={Language.getTitleCase('Full name')}
                                        name='name'
                                        placeholder={`${Language.getTitleCase('example')}: Donnie Brasco`}
                                        value={info.name}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <Drip7Input
                                        title={Language.getTitleCase('nickname')}
                                        name='nickname'
                                        placeholder={Language.getSentenceCase('enter a Nickname')}
                                        value={info.nickname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div
                                className="mt-6 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-4">
                                    <Drip7Input
                                        title={Language.getTitleCase('email')}
                                        name='email'
                                        type='email'
                                        placeholder='email@address.com'
                                        value={info.email}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="sm:col-span-2">
                                    <div className='input-wrap'>
                                        <label htmlFor='department'
                                               className="block text-sm font-semibold leading-6 text-gray-800 mb-1"
                                               style={{fontSize: '0.875rem'}}>
                                            {Language.getTitleCase('groups')}
                                        </label>
                                        <button className='clear-button'
                                                onClick={toggleGroupModal}>
                                            {membership.length.toString() + " " + Language.getString('selected')}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-6 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-2">
                                    <Drip7Combobox
                                        entries={Object.entries(MemberRole).map(([key, role]) => ({
                                            name: Language.getTitleCase(role),
                                            uid: role
                                        }))}
                                        selected_idx={Object.values(MemberRole).indexOf(info.role)}
                                        onSelected={(idx) => handleChange({
                                            target: {
                                                name: 'role',
                                                value: Object.values(MemberRole)[idx]
                                            }
                                        })}
                                        title={Language.getTitleCase("access level")}
                                        className="w-96"
                                    />
                                </div>

                                <div className="sm:col-start-4 sm:col-span-2">
                                    <Drip7Combobox
                                        entries={Object.entries(Notification).map(([key, value]) => ({
                                            name: Language.getTitleCase(value),
                                            uid: value
                                        }))}
                                        selected_idx={Object.values(Notification).indexOf(info.notify)}
                                        onSelected={(idx) => handleChange({
                                            target: {
                                                name: 'notify',
                                                value: Object.values(Notification)[idx]
                                            }
                                        })}
                                        title={Language.getTitleCase("notifications")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className='personal-info-wrap'>

                <div className='user-edit-inputs'>

                    {show_pwd_reset && (
                        <div className='password-wrap'>
                            <button
                                className='reset-password solid-button'
                                onClick={handleResetPassword}>
                                {Language.getTitleCase('Reset Password')}
                            </button>
                        </div>
                    )}

                    {tenant.presentation &&
                        <div className='customization-checkbox'>
                            <input
                                type='checkbox'
                                aria-label="checkbox"
                                name='presentation'
                                readOnly
                                id='presentation'
                                onChange={handleChecked}
                                checked={info.presentation}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='presentation'>
                                {Language.getTitleCase('Presentation user')}
                            </label>
                        </div>
                    }
                </div>

            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>

            <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                <Drip7Button
                    className="mr-3"
                    onClick={onClose}
                    mode='outlined'>
                    {Language.getTitleCase('cancel')}
                </Drip7Button>
                <Drip7Button
                    onClick={handleSaveChanges}
                    mode='filled'>
                    {Language.getTitleCase('Save Changes')}
                </Drip7Button>
            </div>

            {confirm != null && (
                <ConfirmModal
                    msg={confirm.msg}
                    yesTxt={confirm.yes}
                    noTxt={confirm.no}
                    onYes={() => confirm.action(true)}
                    onNo={() => setState(prev => ({
                        ...prev,
                        confirm: null
                    }))}
                />
            )}
        </div>
    );
}
