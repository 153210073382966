import Util from "../helpers/util";

export const Drip7Slider = props => {
    const { id, name, value, onChange } = props
    const step = props.step || 1
    const min = props.min || 0
    const max = props.max || 100
    const display = props.display || ((x) => x)

    return (
        <div className="w-full max-w-lg mx-auto">
            <input
                id={id}
                name={name}
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={e => onChange({ target: { id, name, value: parseInt(e.target.value) }})}
                className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer accent-drip7"
            />
            <div className="mt-2 text-center text-gray-700">
                {display(value)}
            </div>
        </div>
    );
}