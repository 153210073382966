import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";

export const AnswerTypeAiChat = React.forwardRef((props, ref) => {
    const { answer, value, question_uid, onChanged, showToast } = props;
    const [state, setState] = useState({
        user_answer: Util.xstr(value),
        submitted: (value != null && value != undefined),
        spinner: false,
        responses: [],
    })
    const {user_answer, submitted, spinner, responses} = state

    let answer_display = ""
    if ( 'correct' in answer ) {
        answer_display = Util.isObject(answer.correct) && 0 in answer.correct? answer.correct[0] : answer.correct;
    }

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            setState(prev => ({...prev,
                user_answer: "",
                submitted: false,
            }))
        },
        attemptAnswer: () => {
            if (user_answer == "") {
                return false;
            }

            handleSubmit( user_answer )
            return true;
        }
    }));

    const isCorrect = (user_answer) => {
        const ualc = user_answer.toLowerCase();

        //Is this answer now correct?
        if ( !('correct' in answer) ) {
            return true
        }
        else if (typeof answer.correct == "object") {
            for (let i = 0; i < answer.correct.length; i++) {
                const ans = answer.correct[i]
                    .toLowerCase()
                    .replace(/^\s+/, "")
                    .replace(/\s+$/, "");
                if ( ualc.search(ans) >= 0 ) {
                    return true
                }
            }
        }
        else {
            const ans = answer.correct
                .toLowerCase()
                .replace(/^\s+/, "")
                .replace(/\s+$/, "");
            return ualc.search(ans) >= 0;
        }

        return false
    }

    const handleKeypress = e => {
        // Enter pressed? login
        if ( e.charCode == 13 ) {
            handleSubmit( user_answer )
        }
    };

    const handleChange = e => {
        setState(prev => ({ ...prev,
            user_answer: e.target.value
        }))
    }

    const handleSubmit = ( user_answer ) => {
        const responses = state.responses.map(r => r.response)
        const answers = state.responses.map(r => r.user_answer)
        answers.push( user_answer )

        //Send to chat for answer
        Util.fetch_js('/client/question/ai_chat/', { question_uid, answers, responses },
            js => {
                const new_responses = state.responses.slice()
                new_responses.push({
                    user_answer,
                    response: js.response,
                })
                setState(prev => ({...prev,
                    user_answer: "",
                    responses: new_responses,
                    spinner: false,
                }))
            },
            (err, code) => {
                showToast(err, "failure")
                setState(prev => ({...prev,
                    user_answer: "",
                    spinner: false,
                }))
            })

        setState(prev => ({...prev,
            spinner: true,
        }))
        /*
        onChanged( user_answer, isCorrect( user_answer ), answer_display );
        setState(prev => ({...prev,
            submitted: true,
        }))
         */
    }

    const klass = () => {
        if (!submitted) {
            return ""
        }

        const mod = isCorrect(user_answer) ? "correct": "incorrect";
        return ` daily-questions__fitb-answer--${mod}`
    };

    return (
        <>
            {Object.entries(responses).map(([key, value]) =>
                <div key={`response_key_${key}`}>
                    <div className='daily-questions__fitb'>
                        <textarea
                            className={"daily-questions__fitb-answer" + klass()}
                            placeholder='Type Answer Here'
                            disabled={true}
                            value={value.user_answer}
                        />
                    </div>
                    <div className='daily-questions__fitb' key={`question_key_${key}`}>
                        <p>
                            {value.response}
                        </p>
                    </div>
                </div>
            )}

            <div className='daily-questions__fitb'>
                <textarea
                    className={"daily-questions__fitb-answer" + klass()}
                    placeholder='Type Answer Here'
                    disabled={submitted || spinner}
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                    value={user_answer}
                />
            </div>
            {spinner &&
            <div className="spinner-border text-primary" style={{ width: '64px', height: '64px'}} role="status">
                <span className="sr-only"></span>
            </div>
            }
        </>
    );
});
