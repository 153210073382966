import React, { useState } from "react";
import Language from "../../helpers/language";
import Util from "../../helpers/util";

import ReactMarkdown from 'react-markdown';
import {ReviewPdf} from "../../modals/user/review_pdf";
import { HOST } from "../../settings";

export const ContentTypeDocument = props => {
    const {content} = props;
    const onReviewedPolicy = props.onReviewedPolicy || (() => {})

    const [state, setState] = useState({
        has_reviewed: false,
        show: false,
    });
    const {has_reviewed, show} = state;

    let text = "text" in content ? content.text : "";
    text = Util.hyperlinkMD(text)

    console.log(content)

    const handleReview = (show) => {
        console.log("Reviewing policy")
        onReviewedPolicy()
        setState(prev => ({ ...prev,
            has_reviewed: true,
            show,
        }))
    }

    //Regex to remove from the last '/', keep everything until '.pdf'
    const matches = content.document.match(/([^/]+)(?=\.\w+$)/)
    const content_uid = (matches && matches.length > 0) ? matches[0] : null
    const content_url = `${HOST}/client/policy/${content_uid}/`
    //console.log(content_url)

    //{parse(text)}
    return (
        <div className='daily-questions__question flex-col items-center pb-5'>
            <div className="w-full inline-flex justify-content-center">
                <ReactMarkdown className="sm:w-1/2">
                    {text}
                </ReactMarkdown>
            </div>
            <div className="cursor-pointer text-drip7 font-semibold w-fit inline-flex" onClick={() => handleReview(true)}>
                {Language.getTitleCase("Review Policy")}
                {!has_reviewed &&
                    <div className="ml-2">
                        <span className="relative flex ml-auto h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span>
                    </div>
                }
            </div>
            <ReviewPdf
                open={show}
                content_url={content_url}
                onClose={() => handleReview(false)}
            />
        </div>
    );
};
